<template>
  <div class="default-view-container">
    <h1 class="mb-0">Report Manager</h1>
    <div v-if="isAvailable" class="default-view increase-padding-bottom mt-4 lg:mt-5">
      <div class="default-view-body">
        <TabView lazy>
          <TabPanel header="Reports">
            <ReportsReportsView/>
          </TabPanel>
          <TabPanel header="Schedules">
            <ReportsSchedulesView/>
          </TabPanel>
          <TabPanel header="Templates">
            <ReportsReportsView mode="templates"/>
          </TabPanel>
          <TabPanel header="Elements" v-if="authState.permissions?.BitpoolAdmin">
            <ReportsElementsView/>
          </TabPanel>
        </TabView>
      </div>
    </div>
    <div v-else class="default-view">
      <Message severity="error" :closable="false">Not enough rights</Message>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Message from 'primevue/message';
import AuthState from "@/store/states/AuthState";
import ReportsReportsView from "./ReportsReportsView.vue";
import ReportsSchedulesView from "./ReportsSchedulesView.vue";
import ReportsElementsView from "./ReportsElementsView.vue";

@Component({
  
  components: {
    TabView,
    TabPanel,
    Message,
    ReportsReportsView,
    ReportsSchedulesView,
    ReportsElementsView
  },
})
class ReportsView extends Vue { 
  get authState(): AuthState {
    return this.$store.state.auth;
  }

  get isAvailable(): boolean | undefined {
    const result = (this.authState.permissions?.FullAccess || this.authState.permissions?.Reports);
    return result;
  }
}

export default ReportsView;
</script>