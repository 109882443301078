import { AggregatedDataHighchartsResponse } from "@/models/AggregatedDataHighchartsResponse";
import { Report3ElementFeatures, Report3ElementFeaturesToString } from "@/models/reports/v3/Report3ElementFeatures";
import { Reports3Datasource } from "@/models/reports/v3/Reports3Datasource";
import { Reports3ElementConfiguration } from "@/models/reports/v3/Reports3ElementConfiguration";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";
import { Reports3Entity } from "@/models/reports/v3/Reports3Entity";
import { Reports3ItemRole } from "@/models/reports/v3/Reports3ItemRole";
import { v4 as uuidv4 } from "uuid";
import TextHelper from "./TextHelper";
import StreamOption from "@/models/dashboard/StreamOption";
import DataHelper from "./DataHelper";
import DateHelper from "./DateHelper";

class ReportPdfHelper {
  public buildDataIdentifier(element: Reports3ElementEntity,
    // @ts-ignore
    elementConfiguration: Reports3ElementConfiguration | null,
    datasource: Reports3Datasource | null,
    report: Reports3Entity | null
  ): string {
    let dataIdentifier = `${datasource?.Uid}/${report?.Id}`;
    if (element.Features.includes(Report3ElementFeatures.DataOnlyTotalAggregation)) {
      dataIdentifier += "/total";
    }
    if (element.Features.includes(Report3ElementFeatures.IncludePreviousPeriod)) {
      dataIdentifier += "/previous";
    }
    return dataIdentifier;
  }

  public buildAIDataIdentifier(element: Reports3ElementEntity,
    elementConfiguration: Reports3ElementConfiguration | null,
    datasource: Reports3Datasource | null,
    report: Reports3Entity | null
  ): string {
    let dataIdentifier = `${datasource?.Uid}/${report?.Id}`;
    if (element.Features.includes(Report3ElementFeatures.DataOnlyTotalAggregation)) {
      dataIdentifier += "/total";
    }
    if (element.Features.includes(Report3ElementFeatures.IncludePreviousPeriod)) {
      dataIdentifier += "/previous";
    }
    if (elementConfiguration) {
      dataIdentifier += `/${elementConfiguration.Uid}`;
    }
    return dataIdentifier;
  }

  public generateHtmlForPdf(report: Reports3Entity, elements: Reports3ElementEntity[],
    data: Record<string, AggregatedDataHighchartsResponse[] | null>,
    globalStyles: string = "", organisationName: string = "Organisation",
    aiInsights: Record<string, [string, string] | null> | null = null,
    utcOffset: number | null = null
  ): string {
    const [gridHtml, gridCss, gridJs] = this.generateGridHtml(report, report.Items, elements, data, organisationName, aiInsights, utcOffset);
    const globalJs = `
      document.body.style.width = "calc(21cm - ${report.MarginLeft}cm - ${report.MarginRight}cm)";
      const elementListReady = [];
      const elementList = [];
      async function reportReady() {
        await document.fonts.ready;
        const timeout = 60000; // 1 minute
        const timeStart = Date.now();
        while (true) {
          if (elementListReady.length >= elementList.length) {
            // check if all elements are ready
            let isOk = true;
            for (const element of elementList) {
              if (!elementListReady.includes(element)) {
                isOk = false;
                break;
              }
            }
            if (isOk) {
              break;
            }
          }
          if (Date.now() - timeStart > timeout) {
            break;
          }
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }`;
    return this.getGeneratedPage(gridHtml,`${globalStyles}\n${gridCss}`, `${globalJs}\n${gridJs}`);
  }

  private generateGridHtml(report: Reports3Entity, configurations: Reports3ElementConfiguration[], elements: Reports3ElementEntity[],
    data: Record<string, AggregatedDataHighchartsResponse[] | null>,
    organisationName: string = "Organisation",
    aiInsights: Record<string, [string, string] | null> | null = null,
    utcOffset: number | null = null
  ): [string, string, string] {
    let resultHtml = "";
    let resultCss = "";
    let resultJs = "";
    for (const configuration of configurations) {
      if (configuration.Role === Reports3ItemRole.Grid) {
        if (configuration.Items?.length) {
          const [html, css, js] = this.generateGridHtml(report, configuration.Items, elements, data,  organisationName, aiInsights, utcOffset);
          resultHtml = `${resultHtml}<div class="bp-col-${configuration.Size}">${html}</div>`;
          resultCss = `${resultCss}\n${css}`;
          resultJs = `${resultJs}\n${js}`;
        }
      } else {
        const [html, css, js] = this.generateElementHtml(report, configuration, elements, data, organisationName, aiInsights, utcOffset);
        resultHtml = `${resultHtml}<div class="bp-col-${configuration.Size}">${html}</div>`;
        resultCss = `${resultCss}\n${css}`;
        resultJs = `${resultJs}\n${js}`;
      }
    }

    return [`<div class="bp-grid">${resultHtml}</div>`, resultCss, resultJs];
  }

  private generateElementHtml(report: Reports3Entity, configuration: Reports3ElementConfiguration, elements: Reports3ElementEntity[],
    data: Record<string, AggregatedDataHighchartsResponse[] | null>,
    organisationName: string = "Organisation",
    aiInsights: Record<string, [string, string] | null> | null = null,
    utcOffset: number | null = null
  ): [string, string, string] {
    const element = configuration.ElementId ? elements.find(x => x.Id === configuration.ElementId) : undefined;
    if (!element) {
      return ["", "", ""];
    }

    const elemenDataConfiguration: Reports3Datasource | null = JSON.parse(JSON.stringify(report.Datasources.find(x => x.Uid === configuration.DatasourceId) ?? null));
    const dataIdentifier = this.buildDataIdentifier(element, configuration, elemenDataConfiguration, report);
    const elementData: AggregatedDataHighchartsResponse[] | null = data[dataIdentifier] ? JSON.parse(JSON.stringify(data[dataIdentifier])) : null;
    
    const aiDataIdentifier = this.buildAIDataIdentifier(element, configuration, elemenDataConfiguration, report);
    let aiInsight: [string, string] | null = null;
    if (aiInsights && aiInsights[aiDataIdentifier]) {
      aiInsight = aiInsights[aiDataIdentifier];
    }
    
    if (elemenDataConfiguration && configuration?.FeaturesConfiguration) {
      const featuresConfiguration = configuration.FeaturesConfiguration;
      
      const keyDataStreams = "DataStreams";
      if (featuresConfiguration[keyDataStreams]) {
        let streamOptions: StreamOption[] = [];
        const streamOptionsSource = elemenDataConfiguration.Configuration.streamOptions.filter(x => x.StreamKey);
        const removeDataIndexes: number[] = [];
        const uids = Object.getOwnPropertyNames(featuresConfiguration[keyDataStreams]);
        if (uids.length > 0) {
          for (let i = 0; i < streamOptionsSource.length; i++) {
            const dataStream = streamOptionsSource[i];
            if (dataStream.Uid && featuresConfiguration[keyDataStreams][dataStream.Uid]) {
              streamOptions.push(dataStream);
            } else {
              removeDataIndexes.push(i);
            }
          }
        } else {
          for (let i = 0; i < streamOptionsSource.length; i++) {
            removeDataIndexes.push(i);
          }
        }
        
        if (elementData) {
          if (removeDataIndexes.length && element.Features.includes(Report3ElementFeatures.IncludePreviousPeriod)) {
            const removePreviousDataIndexes: number[] = [];
            for (let i = 0; i < removeDataIndexes.length; i++) {
              removePreviousDataIndexes.push(removeDataIndexes[i] + streamOptionsSource.length);
            }
            removeDataIndexes.push(...removePreviousDataIndexes);
          }
          for (let i = removeDataIndexes.length - 1; i >= 0; i--) {
            elementData.splice(removeDataIndexes[i], 1);
          }
        }
        elemenDataConfiguration.Configuration.streamOptions = streamOptions;
      }
      
      const keyDataSeriesType = Report3ElementFeaturesToString[Report3ElementFeatures.DataSeriesType];
      if (featuresConfiguration[keyDataSeriesType]) {
        for (const streamOption of elemenDataConfiguration.Configuration.streamOptions) {
          if (streamOption.Uid && featuresConfiguration[keyDataSeriesType][streamOption.Uid]) {
            const value = featuresConfiguration[keyDataSeriesType][streamOption.Uid];
            streamOption.type = value;
          }
        }
      }
      
      const keyDataSeriesTypeStacked = Report3ElementFeaturesToString[Report3ElementFeatures.DataSeriesTypeStacked];
      if (featuresConfiguration[keyDataSeriesTypeStacked]) {
        for (const streamOption of elemenDataConfiguration.Configuration.streamOptions) {
          if (streamOption.Uid && featuresConfiguration[keyDataSeriesTypeStacked][streamOption.Uid]) {
            const value = featuresConfiguration[keyDataSeriesTypeStacked][streamOption.Uid];
            streamOption.stackedType = value;
          }
        }
      }

      const keyDataYAxis = Report3ElementFeaturesToString[Report3ElementFeatures.YAxis];
      if (featuresConfiguration[keyDataYAxis]) {
        for (const streamOption of elemenDataConfiguration.Configuration.streamOptions) {
          if (streamOption.Uid && featuresConfiguration[keyDataYAxis][streamOption.Uid]) {
            const value = featuresConfiguration[keyDataYAxis][streamOption.Uid];
            streamOption.Params.yaxis = value;
          }
        }
      }

      const keyDataDataGroups = Report3ElementFeaturesToString[Report3ElementFeatures.DataGroups];
      if (featuresConfiguration[keyDataDataGroups]) {
        for (const streamOption of elemenDataConfiguration.Configuration.streamOptions) {
          if (streamOption.Uid && featuresConfiguration[keyDataDataGroups][streamOption.Uid]) {
            const value = featuresConfiguration[keyDataDataGroups][streamOption.Uid];
            streamOption.Params.groupIndex = value;
          }
        }
      }
    }
    
    const parentId = `report-element-${uuidv4()}`;
    const parameters: Record<string, any> = {};
    if (element.AdditionalParameters) {
      for (const param of element.AdditionalParameters) {
        parameters[param.Name] = param.DefaultValue;
      }
    }
    if (configuration?.AdditionalParameters) {
      for (const key in configuration.AdditionalParameters) {
        parameters[key] = configuration.AdditionalParameters[key];
      }
    }
    parameters.organisationName = organisationName;
    parameters.reportName = report.Name;
    if (aiInsight) {
      parameters.aiQuestion = aiInsight[0];
      parameters.aiAnswer = aiInsight[1];
    }
    const now = new Date();
    if (typeof utcOffset === "number") {
      now.setUTCMinutes(now.getUTCMinutes() + now.getTimezoneOffset() + utcOffset);
    }
    const parts = DateHelper.dateToArray(now);
    parameters.todayYear = parts[0];
    parameters.todayMonth = parts[1];
    parameters.todayDay = parts[2];
    parameters.today = `${parts[2]}/${parts[1]}/${parts[0]}`;

    // Extract date range from data configuration
    const isTotal = element.Features.includes(Report3ElementFeatures.DataOnlyTotalAggregation);
    const requestBody = elemenDataConfiguration ?
      DataHelper.wdsToApiRequest(
        elemenDataConfiguration.Configuration, 
        report && elemenDataConfiguration.UseReportDRS ? report.DateRange : null,
        isTotal ? "reports3_total" : "reports3"
      ) : null;
    let dateRange: [string, string] | null = null;
    if (requestBody) {
      const dates = DateHelper.extractDateFromRequestBody(requestBody);
      dateRange = [DateHelper.dateToString(dates[0]), DateHelper.dateToString(dates[1])];
    }
    parameters.dateRange = dateRange;
    
    let htmlWithParameters = element.HTML;
    let cssWithParameters = element.CSS;
    for (const key in parameters) {
      htmlWithParameters = htmlWithParameters.replace(new RegExp(`\\{\\{${key}\\}\\}`, 'g'), parameters[key]);
      cssWithParameters = cssWithParameters.replace(new RegExp(`\\{\\{${key}\\}\\}`, 'g'), parameters[key]);
    }
    // function displayData(parent, parameters, data, dataConfiguration, libraries, onReadyEvent)
    const result: [string, string, string] = [
      `<div style="display: flex;flex-direction: column;min-height: 100%;padding-top: ${configuration.MarginTop}cm; padding-bottom: ${configuration.MarginBottom}cm; padding-left: ${configuration.MarginLeft}cm; padding-right: ${configuration.MarginRight}cm;">
        <div id="${parentId}" class="report-element" style="${configuration.BreakInside ? 'break-inside: avoid;' : 'break-inside: auto;'}">${htmlWithParameters}</div>
      </div>`,
      `#${parentId} { ${cssWithParameters} }`,
      `elementList.push("${parentId}");
      (function() {
        const onReadyEvent = function() {
          elementListReady.push("${parentId}");
        };
        try {
          ${element.JavaScript}
          if (displayData) {
            const parameters = ${JSON.stringify(parameters)};
            const data = ${JSON.stringify(TextHelper.objectKeysToCamelCase(elementData))};
            const dataConfiguration = ${JSON.stringify(TextHelper.objectKeysToCamelCase(elemenDataConfiguration))};
            const libraries = {
              Highcharts: Highcharts,
              marked: marked,
              DOMPurify: DOMPurify
            };
            displayData(document.querySelector("#${parentId}"), parameters, data, dataConfiguration, libraries, onReadyEvent);
          } else {
            onReadyEvent();
          }
        } catch (error) {
          onReadyEvent();
        }
      })();`
    ];
    return result;
  }

  private getGeneratedPage(html: string, css: string, js: string): string {
    const cssLine = css && `<style>${css}</style>`;
    const jsLine = js && `<script>${js}</${"script"}>`; // note: regular script close tag breaks vue parser
    const source = `
      <html>
        <head>
          <script src="https://code.highcharts.com/11.4.8/highcharts.js"></script>
          <script src="https://cdn.jsdelivr.net/npm/marked@15.0.3/lib/marked.umd.min.js"></script>
          <script src="https://cdn.jsdelivr.net/npm/dompurify@3.2.2/dist/purify.min.js"></script>
          ${cssLine}
        </head>
        <body>
          ${html || ''}
          ${jsLine}
        </body>
      </html>`;
    return source;
  }
}

export default new ReportPdfHelper();
