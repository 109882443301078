<template>
  <Dialog header="Dashboard" v-model:visible="displayDialog" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}">
    <div class="dialog-content">
      <BlockUI :blocked="updateDashboardInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="updateDashboardInProgress ? 'blockui-blocked' : ''">
        <div class="field mb-0">
          <label for="dashboardName">Dashboard Name</label>
          <div>
            <InputText
              id="dashboardName"
              class="inputfield w-full"
              type="text"
              v-model="dashboardName"
              @keyup.enter="saveDashboard"
            />
          </div>
        </div>
        <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
      </BlockUI>
    </div>
    <template #footer>
      <Button label="Close" icon="pi pi-times" @click="closeDialog" class="p-button-text p-button-secondary"/>
      <Button label="Save" :icon="updateDashboardInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" @click="saveDashboard" :disabled='updateDashboardInProgress' />
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import InputText from "primevue/inputtext";
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import ProgressSpinner from 'primevue/progressspinner';
import { Space } from "@/models/dashboard/Space";
import DashboardState from "@/store/states/DashboardState";
import SpaceHelper from "@/helpers/SpaceHelper";
import NavigationHelper from "@/helpers/NavigationHelper";
import { useOrganisationStore } from "@/stores/organisation";
import { DashboardType } from "@/models/dashboard/DashboardType";

@Component({
  components: {
    InputText,
    Button,
    Dialog,
    BlockUI,
    ProgressSpinner
  },
})
class DashboardNewDialogView extends Vue {
  displayDialog = false;
  
  organisationStore = useOrganisationStore();

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get dashboardType(): DashboardType {
    return this.dashboardState.dashboardType;
  }
  
  updateDashboardInProgress = false;
  dashboardName = "";
  selectedDashboard: Space | null = null;

  openDialog(parentDashboard: Space): void {
    const dashboard = SpaceHelper.createDashboard(parentDashboard, "New dashboard");
    this.selectedDashboard = dashboard;
    this.dashboardName = dashboard.spaceName;
    this.displayDialog = true;
  }

  closeDialog(): void {
    this.displayDialog = false;
  }

  async saveDashboard(): Promise<void> {
    if (!this.updateDashboardInProgress && this.selectedDashboard && this.dashboardName && this.organisationStore.currentOrganisation) {
      this.updateDashboardInProgress = true;
      this.selectedDashboard.spaceName = this.dashboardName;
      const dashboard = this.selectedDashboard;
      const id = dashboard._id ? dashboard._id : "new_dashboard";
      await this.$store.dispatch(
        "dashboard/saveDashboard", 
        { 
          dashboard: dashboard,
          organisationId: this.dashboardType === DashboardType.Organisation ? this.organisationStore.currentOrganisation.Id : undefined
        }
      );
      const state = this.dashboardState.dashboardState[id];
      if (state && state[0] && !state[2]) {
        if (id === "new_dashboard") {
          NavigationHelper.goToDashboard(this.dashboardType, dashboard._id);
        }
        this.closeDialog();
      }
      this.updateDashboardInProgress = false;
    }
  }
}

export default DashboardNewDialogView;
</script>