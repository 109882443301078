<template>
  <div class="preview-link-embed-content my-3">
    <div v-if="isLoaded">
      <div v-if="data">
        <div class="border-round-md px-3 py-1" style="background-color: var(--ai-link-preview-bg-color);">
          <h5><a :href="link" target="_blank">{{data.Title}}</a></h5>
          <p v-if="data.Excerpt" class="mt-3">{{data.Excerpt}}</p>
          <div v-if="data.FeaturedImage" class="mt-2 mb-3"><img :src="data.FeaturedImage" class="border-round-md vertical-align-top"/></div>
        </div>
      </div>
    </div>
    <div v-else>
      <Skeleton width="60%" height="1rem" borderRadius="6px" class="mb-3"></Skeleton>
      <Skeleton width="85%" height="1rem" borderRadius="6px" class="mb-2"></Skeleton>
      <Skeleton width="85%" height="5rem" borderRadius="6px"></Skeleton>
    </div>
  </div>
</template>

<script lang="ts">
import { ReadPagePreviewResult } from "@/models/bitpool-ai/ReadPagePreviewResult";
import { useBitpoolAIChatStore } from "@/stores/bitpoolAIChat";
import { Component, Model, Vue } from "vue-facing-decorator";
import Skeleton from 'primevue/skeleton';

@Component({
  components: {
    Skeleton
  },
})
class LinkPreviewView extends Vue {
  @Model link!: string;

  bitpoolAIChatStore = useBitpoolAIChatStore();
  data: ReadPagePreviewResult | null = null;
  isLoaded = false;

  mounted(): void {
    this.init();
  }

  async init(): Promise<void> {
    const data = await this.bitpoolAIChatStore.getLinkPreview(this.link);
    this.data = data;
    this.isLoaded = true;
  }
}

export default LinkPreviewView;
</script>