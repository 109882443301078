import { AggregatedDataRequest } from "@/models/AggregatedDataRequest";
import { AggregatedDataHighchartsResponse } from "@/models/AggregatedDataHighchartsResponse";
import DateHelper from "./DateHelper";
import { AggregationType } from "@/models/enums/AggregationType";

class DataGenerator {
  generateData(requestBody: AggregatedDataRequest): AggregatedDataHighchartsResponse[] {
    const result: AggregatedDataHighchartsResponse[] = [];
    const dates = DateHelper.extractDateFromRequestBody(requestBody);
    const startDate = dates[0];
    const endDate = dates[1];
    const step = DateHelper.extractDateStepFromRequestBody(requestBody) ?? 24 * 3600 * 1000; // milliseconds
    for (const stream of requestBody.Streams) {
      let streamStartDate = startDate;
      let streamEndDate = endDate;
      if (stream.Time && stream.Time.From && stream.Time.To) {
        if (typeof stream.Time.From === "string") {
          streamStartDate = new Date(stream.Time.From);
        } else {
          streamStartDate = stream.Time.From;
        }
        if (typeof stream.Time.To === "string") {
          streamEndDate = new Date(stream.Time.To);
        } else {
          streamEndDate = stream.Time.To;
        }
      }
      const data: AggregatedDataHighchartsResponse = {
        StreamKey: stream.StreamKey,
        Data: [
        ],
        Error: null,
        StreamValuesRequest: stream
      };
      let currentDate = streamStartDate;
      const isDiff = stream.AggregationType === AggregationType.Diff;
      let y1 = Math.random() * 100;
      while (currentDate < streamEndDate) {
        const x = currentDate.getTime();
        const y2 = isDiff ? (y1 + Math.random() * 100) : Math.random() * 100000;
        data.Data.push({
          x: x,
          y: isDiff ? (y2 - y1) : y2,
          y1: isDiff ? y1 : undefined,
          y2: isDiff ? y2 : undefined,
          calculated: false
        });

        currentDate = new Date(currentDate.getTime() + step);
        y1 = y2;
      }
      result.push(data);
    }
    return result;
  }
}

export default new DataGenerator();
