<template>
  <div class="flex-auto overflow-y-auto overflow-x-hidden">
    <Accordion class="report-general-settings-accordion" :activeIndex="0">
      <template #collapseicon>
        <i class="p-accordion-toggle-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="2" viewBox="0 0 18 2" fill="none"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17 1H1"/></svg>
        </i>
      </template>
      <template #expandicon>
        <i class="p-accordion-toggle-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17 9H1m8-8v16"/></svg>
        </i>
      </template>
      <AccordionTab header="General" v-if="reports3Store.dataOne">
        <div class="formgrid grid report-general-settings-item">
          <div class="field col-12" v-if="canEditGlobal && mode === 'templates'">
            <div class="flex align-items-center">
              <InputSwitch 
                inputId="editRecordGlobal"
                v-model="selectedEntityIsGlobal"
                class="vertical-align-top"
                :disabled="reports3Store.updateInProgress"
              />
              <label for="editRecordGlobal" class="mb-0 ml-2">Public</label>
            </div>
          </div>
          <div class="field col-12 mb-0">
            <label for="editRecordName">Name</label>
            <div>
              <InputText 
                id="editRecordName" 
                class="inputfield w-full"
                type="text" 
                v-model="reports3Store.dataOne.Name"
                placeholder="Name"
              />
            </div>
          </div>
        </div>
      </AccordionTab>

      <AccordionTab header="Schedule" v-if="reports3Store.dataOneSchedules && mode === 'reports'">
        <div class="formgrid grid report-general-settings-item">
          <div class="field col-12 mb-0">
            <MultiSelect
              inputId="editRecordSchedules"
              v-model="reports3Store.dataOneSchedules"
              :options="reports3SchedulesStore.dataLight"
              :loading="!reports3SchedulesStore.isLoadedLight"
              placeholder="Select Schedules"
              display="chip"
              :filter="true"
              optionValue="Id"
              optionLabel="Name"
              class="p-multiselect-multiline inputfield w-full"
            />
          </div>
        </div>
      </AccordionTab>

      <AccordionTab header="Report date range" v-if="reports3Store.dataOne">
        <div class="formgrid grid report-general-settings-item">
          <div class="field col-12 mb-0">
            <DashboardWidgetGlobalDateRangeView :gdrs="reports3Store.dataOne.DateRange" :rdrsMode="true"/>
          </div>
        </div>
      </AccordionTab>

      <AccordionTab header="Data sources" v-if="reports3Store.dataOne">
        <div class="formgrid grid report-general-settings-item">
          <div class="field col-12 mb-0">
            <ReportsDatasourcesView :mode="mode"/>
          </div>
        </div>
      </AccordionTab>

      <AccordionTab header="Template" v-if="reports3Store.dataOne && mode === 'reports'">
        <div class="formgrid grid report-general-settings-item">
          <div class="field col-12 mb-0">
            <p class="help-text mt-0">
              <span>
                Create a template from this report to use it as a starting point for future reports.
              </span>
            </p>
            <InputGroup>
              <InputText placeholder="Template name" v-model="templateName" :disabled="reports3TemplatesStore.updateInProgress" />
              <Button :disabled="!templateName || reports3TemplatesStore.updateInProgress" label="Create" @click="createTemplateClick" />
            </InputGroup>
          </div>
        </div>
      </AccordionTab>
    </Accordion>
  </div>

  <div v-if="reports3Store.dataOne?.Id" class="report-delete-button-wrapper">
    <Button
      label="Delete report"
      icon="pi pi-trash"
      class="p-button-outlined p-button-danger w-full label-flex-none justify-content-center"
      @click="deleteReport"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import InputText from 'primevue/inputtext';
import Button from "primevue/button";
import MultiSelect from "primevue/multiselect";
import InputSwitch from "primevue/inputswitch";
import InputGroup from "primevue/inputgroup";
import { useReports3Store } from "@/stores/reports3";
import ConfirmationService from "@/services/ConfirmationService";
import NavigationHelper from "@/helpers/NavigationHelper";
import ReportsDatasourcesView from "./ReportsDatasourcesView.vue";
import DashboardWidgetGlobalDateRangeView from "@/components/views/dashboards/DashboardWidgetGlobalDateRangeView.vue";
import { usePageStore } from "@/stores/page";
import { useReports3SchedulesStore } from "@/stores/reports3Schedules";
import AuthState from "@/store/states/AuthState";
import { useOrganisationStore } from "@/stores/organisation";
import { Reports3TemplateEntity } from "@/models/reports/v3/Reports3TemplateEntity";
import moment from "moment";

@Component({
  components: {
    Accordion,
    AccordionTab,
    InputText,
    Button,
    MultiSelect,
    InputSwitch,
    InputGroup,
    ReportsDatasourcesView,
    DashboardWidgetGlobalDateRangeView
  },
  directives: {
  }
})
class ReportsReportRightPanelView extends Vue {
  @Prop({ required: false, default: "reports" }) mode!: string;

  get reports3Store() {
    return useReports3Store(this.mode);
  }
  reports3TemplatesStore = useReports3Store("templates");
  pageStore = usePageStore();
  reports3SchedulesStore = useReports3SchedulesStore();
  organisationStore = useOrganisationStore();
  
  get authState(): AuthState {
    return this.$store.state.auth;
  }

  get canEditGlobal(): boolean {
    return !!this.authState.permissions?.BitpoolAdmin;
  }

  get selectedEntityIsGlobal(): boolean {
    return this.reports3Store.dataOne?.OrganisationId === 0;
  }

  set selectedEntityIsGlobal(value: boolean) {
    if (this.reports3Store.dataOne) {
      if (value) {
        this.reports3Store.dataOne.OrganisationId = 0;
      } else {
        this.reports3Store.dataOne.OrganisationId = this.organisationStore.currentOrganisation?.Id ?? -1;
      }
    }
  }

  deleteReport(): void {
    const message = `Are you sure you want to delete ${this.reports3Store.dataOne?.Name}?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: async () => {
        // callback to execute when user confirms the action
        if (this.reports3Store.dataOne) {
          await this.reports3Store.delete(this.reports3Store.dataOne);
          if (!this.reports3Store.deleteError) {
            this.pageStore.dirty = false;
            NavigationHelper.goTo('/report-manager');
          }
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  templateName = "";

  async createTemplateClick(): Promise<void> {
    if (this.templateName && this.reports3Store.dataOne) {
      const template: Reports3TemplateEntity = JSON.parse(JSON.stringify(this.reports3Store.dataOne));
      template.Id = "";
      template.Name = this.templateName;
      const nowUtc = moment.utc().toDate();
      template.Created = nowUtc;
      template.Updated = nowUtc;
      template.CreatedBy = ""; // api will fill it
      template.UpdatedBy = ""; // api will fill it
      const result = await this.reports3TemplatesStore.createUpdate(template);
      if (result?.Id) {
        NavigationHelper.goTo(`/report-manager/template/${result.Id}`);
      }
    }
  }
}

export default ReportsReportRightPanelView;
</script>