<template>
  <div>
    <div class="flex column-gap-3 align-items-center mb-3 pb-1">
      <label class="mb-0">Parameters</label>
      <Button
        @click="() => { additionalParameters.push({ Name: `parameter${(additionalParameters.length + 1)}`, Label: `Parameter ${(additionalParameters.length + 1)}`, DefaultValue: '', Type: ScriptAdditionalParameterType.String, HelpText: '', CSSClass: '' }); }"
        class="p-button-outlined p-button-sm text-sm flex-shrink-0"
        icon="pi pi-plus"
        label="Add"
      />
    </div>
    <div v-if="additionalParameters.length">
      <DataTable :value="additionalParameters" showGridlines responsiveLayout="stack" breakpoint="600px"
        class="data-small-table responsive-breakpoint p-datatable-sm">
        <Column field="Name" header="Name" headerClass="text-sm" bodyClass="text-sm" headerStyle="min-width: min-content;">
          <template #body="{ data, field }">
            <InputText class="w-full" v-model="data[field]" @input="validateAdditionalParameterName($event, data)" />
          </template>
        </Column>
        <Column field="Label" header="Label" headerClass="text-sm" bodyClass="text-sm" headerStyle="min-width: min-content;">
          <template #body="{ data, field }">
            <InputText class="w-full" v-model="data[field]" />
          </template>
        </Column>
        <Column  v-if="!extendedMode" field="Type" header="Type" headerClass="text-sm" bodyClass="text-sm" headerStyle="min-width: min-content;">
          <template #body="{ data, field }">
            <Dropdown 
              v-model="data[field]" 
              :options="scriptAdditionalParameterTypes" 
              :optionLabel="(x: string): string => { return x[1]; }"
              :optionValue="(x: string): string => { return x[0]; }"
              @change="onTypeChange(data)" 
              placeholder="Select type" 
              class="w-full"
            />
          </template>
        </Column>
        <Column field="DefaultValue" :header="extendedMode ? 'Value' : 'Default Value'" headerClass="text-sm" bodyClass="text-sm" headerStyle="min-width: min-content;">
          <template #body="{ data, field }">
            <div v-if="extendedMode">
              <label>Type</label>
              <Dropdown 
                v-model="data.Type" 
                :options="scriptAdditionalParameterTypes" 
                :optionLabel="(x: string): string => { return x[1]; }"
                :optionValue="(x: string): string => { return x[0]; }"
                @change="onTypeChange(data)" 
                placeholder="Select type" 
                class="w-full"
              />
            </div>
            <div :class="extendedMode ? 'mt-3' : ''" v-if="data.Type !== ScriptAdditionalParameterType.GroupTitle && data.Type !== ScriptAdditionalParameterType.Divider">
              <label v-if="extendedMode">Default Value</label>
              <InputText 
                v-if="data.Type === ScriptAdditionalParameterType.String"
                class="w-full" 
                v-model="data[field]"
              />
              <InputNumber
                v-else-if="data.Type === ScriptAdditionalParameterType.Number"
                class="w-full" 
                v-model="data[field]"
                :minFractionDigits="2"
                :maxFractionDigits="20"
              />
              <InputSwitch 
                v-else-if="data.Type === ScriptAdditionalParameterType.Boolean"
                v-model="data[field]"
                class="vertical-align-middle"
              />
              <ColorSelectView 
                v-else-if="data.Type === ScriptAdditionalParameterType.Color"
                :color="data[field]" 
                :updateColor="(color: string) => { 
                  data[field] = color;
                }"
              />
              <div v-else-if="data.Type === ScriptAdditionalParameterType.Image" class="image-inside-table">
                <img class="image-inside-table-img" v-if="data[field]" :src="data[field]" />
                <ImageGalleryDialogButtonView class="image-inside-table-btn"
                  @imageSelected="(entity: ImageGalleryEntity | null) => { data[field] = entity ? entity.OptimizedFilename ? `${uploadUrl}/${entity.OptimizedFilename}` : `${uploadUrl}/${entity.Filename}` : '' }"
                />
              </div>
              <Textarea 
                v-else-if="data.Type === ScriptAdditionalParameterType.StringMultiLine"
                class="w-full" 
                style="white-space: pre-wrap;"
                v-model="data[field]"
                :autoResize="true" 
                :rows="3"
              />
              <Dropdown 
                v-else-if="data.Type === ScriptAdditionalParameterType.FontFamily"
                v-model="data[field]"
                :options="FontHelper.fontFamilies"
                placeholder="Select font family" 
                class="w-full"
              />
              <Dropdown 
                v-else-if="data.Type === ScriptAdditionalParameterType.FontSize"
                v-model="data[field]"
                :options="FontHelper.fontSizesWithLabel"
                optionValue="value"
                optionLabel="label"
                placeholder="Select font size" 
                class="w-full"
              />
              <Dropdown 
                v-else-if="data.Type === ScriptAdditionalParameterType.FontWeight"
                v-model="data[field]"
                :options="FontHelper.fontWeights"
                placeholder="Select font weight" 
                class="w-full"
              />
              <Dropdown 
                v-else-if="data.Type === ScriptAdditionalParameterType.FontStyle"
                v-model="data[field]"
                :options="FontHelper.fontStyles"
                placeholder="Select font style" 
                class="w-full"
              />
              <Dropdown 
                v-else-if="data.Type === ScriptAdditionalParameterType.TextAlign"
                v-model="data[field]"
                :options="FontHelper.textAligns"
                placeholder="Select text align" 
                class="w-full"
              />
              <Dropdown 
                v-else-if="data.Type === ScriptAdditionalParameterType.AlignHorizontal"
                v-model="data[field]"
                :options="FontHelper.alignsHorizontal"
                placeholder="Select horizontal align" 
                class="w-full"
              />
              <Dropdown 
                v-else-if="data.Type === ScriptAdditionalParameterType.AlignVertical"
                v-model="data[field]"
                :options="FontHelper.alignsVerticalWithIcons"
                optionValue="value"
                optionLabel="label"
                placeholder="Select vertical align" 
                class="w-full"
              />
              <Dropdown 
                v-else-if="data.Type === ScriptAdditionalParameterType.ImageSizeBackground"
                v-model="data[field]"
                :options="FontHelper.imageSizesBackground"
                optionValue="value"
                optionLabel="label"
                placeholder="Select image size" 
                class="w-full"
              />
              <Dropdown 
                v-else-if="data.Type === ScriptAdditionalParameterType.ImageSize"
                v-model="data[field]"
                :options="FontHelper.imageSizes"
                optionValue="value"
                optionLabel="label"
                placeholder="Select image size" 
                class="w-full"
              />
            </div>
          </template>
        </Column>
        <Column v-if="extendedMode" field="HelpText" header="Additional" headerClass="text-sm" bodyClass="text-sm" headerStyle="min-width: min-content;">
          <template #body="{ data, field }">
            <div>
              <label>Help Text</label>
              <Textarea 
                class="w-full" 
                v-model="data.HelpText"
                :autoResize="true" 
                :rows="3"
              />
            </div>
            <div class="mt-3">
              <label>CSS Class</label>
              <InputText 
                class="w-full" 
                v-model="data.CSSClass"
              />
            </div>
          </template>
        </Column>
        <Column field="" header="" headerStyle="width: 1%; min-width: 128px; border-left-color: transparent;"
          bodyStyle="text-align: right; justify-content: flex-end;">
          <template #body="{ index }">
            <div class="flex-none flex gap-2">
              <Button class="p-button-outlined p-button-icon-only p-button-rounded widget-settings-control-btn"
                icon="pi pi-arrow-up" @click="() => moveParameter(index, index - 1)" :disabled="index === 0" v-tippy="'Move Up'">
              </Button>
              <Button class="p-button-outlined p-button-icon-only p-button-rounded widget-settings-control-btn"
                icon="pi pi-arrow-down" @click="() => moveParameter(index, index + 1)"
                :disabled="index === additionalParameters.length - 1" v-tippy="'Move Down'">
              </Button>
              <Button icon="pi pi-trash"
                class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined"
                @click="() => { additionalParameters.splice(index, 1) }" v-tippy="'Delete'" />
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Model, Prop, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputSwitch from 'primevue/inputswitch';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import { ScriptAdditionalParameterType } from "@/models/script/ScriptAdditionalParameterType";
import { ScriptAdditionalParameter } from "@/models/script/ScriptAdditionalParameter";
import ColorSelectView from '@/components/widgets-next/settings/ColorSelectView.vue';
import { ImageGalleryEntity } from "@/models/image-gallery/ImageGalleryEntity";
import ImageGalleryDialogButtonView from "@/components/views/image-gallery/ImageGalleryDialogButtonView.vue"
import FontHelper from "@/helpers/FontHelper";
import { arrayMoveMutable } from "array-move";

@Component({
  components: {
    Button,
    InputText,
    InputNumber,
    DataTable,
    Column,
    InputSwitch,
    Dropdown,
    Textarea,
    ColorSelectView,
    ImageGalleryDialogButtonView
  },
  directives: {
  }
})
class ScriptAdditionalParameterView extends Vue { 
  @Model({ required: true }) additionalParameters!: ScriptAdditionalParameter[];
  @Prop({ required: false, default: false }) extendedMode!: boolean;

  ScriptAdditionalParameterType = ScriptAdditionalParameterType;

  get scriptAdditionalParameterTypes(): [ScriptAdditionalParameterType, string][] {
    let result = Object
      .keys(ScriptAdditionalParameterType)
      .map(key => [ScriptAdditionalParameterType[key as any], key])
      .filter(value => typeof value[0] === 'number') as [ScriptAdditionalParameterType, string][];
    if (!this.extendedMode) {
      // delete types that are not supported in basic mode, keep 3 first types
      result = result.slice(0, 3);
    }
    return result;
  }

  validateAdditionalParameterName(event: Event, data: ScriptAdditionalParameter): void {
    // Remove any non-alphanumeric characters
    const sanitizedValue = (event.target as HTMLInputElement).value.replace(/[^a-zA-Z0-9_]/g, "");

    // Ensure the first character is alphabetic
    const firstChar = sanitizedValue.charAt(0);
    const isAlpha = /^[a-zA-Z]+$/.test(firstChar);

    if (isAlpha) {
      data.Name = sanitizedValue;
    } else {
      // If the first character is not alphabetic, remove it
      data.Name = sanitizedValue.slice(1);
    }
  }

  onTypeChange(data: ScriptAdditionalParameter): void {
    switch (data.Type) {
      case ScriptAdditionalParameterType.String:
        data.DefaultValue = "";
        break;
      case ScriptAdditionalParameterType.Number:
        data.DefaultValue = 0;
        break;
      case ScriptAdditionalParameterType.Boolean:
        data.DefaultValue = false;
        break;
      case ScriptAdditionalParameterType.Color:
        data.DefaultValue = "#000000";
        break;
      case ScriptAdditionalParameterType.Image:
        data.DefaultValue = "";
        break;
      case ScriptAdditionalParameterType.StringMultiLine:
        data.DefaultValue = "";
        break;
      case ScriptAdditionalParameterType.FontFamily:
        data.DefaultValue = "Poppins";
        break;
      case ScriptAdditionalParameterType.FontSize:
        data.DefaultValue = 16;
        break;
      case ScriptAdditionalParameterType.FontWeight:
        data.DefaultValue = "400";
        break;
      case ScriptAdditionalParameterType.FontStyle:
        data.DefaultValue = "normal";
        break;
      case ScriptAdditionalParameterType.TextAlign:
        data.DefaultValue = "left";
        break;
      case ScriptAdditionalParameterType.GroupTitle:
      case ScriptAdditionalParameterType.Divider:
        data.DefaultValue = "";
        break;
      case ScriptAdditionalParameterType.AlignHorizontal:
        data.DefaultValue = "left";
        break;
      case ScriptAdditionalParameterType.AlignVertical:
        data.DefaultValue = "top";
        break;
      case ScriptAdditionalParameterType.ImageSizeBackground:
        data.DefaultValue = "cover";
        break;
      case ScriptAdditionalParameterType.ImageSize:
        data.DefaultValue = "auto";
        break;
    }
  }

  get uploadUrl() {
    return `${this.$store.state.apiUrl}/rest/AWS_S3_V1/File`;
  }

  FontHelper = FontHelper;

  moveParameter(fromIndex: number, toIndex: number): void {
    arrayMoveMutable(this.additionalParameters, fromIndex, toIndex);
  }
}

export default ScriptAdditionalParameterView;
</script>