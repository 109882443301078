<template>
  <div class="openai-comments-item-body-wrap">
    <TypingTextView 
      v-if="message.author === 'You'"
      class="openai-comments-item-body break-word comment-user"
      :message="message"
      scrollToParent=".openai-comments-item"
    />
    <TypingTextView 
      v-else
      class="openai-comments-item-body break-word comment-bot"
      :message="message"
      scrollToParent=".openai-comments-item"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import TypingTextView from "@/components/views/TypingTextView.vue";
import { ChatGPTHistory } from "@/models/bitpool-ai/ChatGPTHistory";

@Component({
  components: {
    TypingTextView
  },
})
class BitpoolAIMessageView extends Vue {
  @Prop({ required: true }) message!: ChatGPTHistory;
  @Prop({ required: true }) index!: number;
}

export default BitpoolAIMessageView;
</script>