import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import Reports3DataState from "./states/Reports3DataState";
import { AggregatedDataHighchartsResponse } from "@/models/AggregatedDataHighchartsResponse";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";
import { Reports3ElementConfiguration } from "@/models/reports/v3/Reports3ElementConfiguration";
import { Reports3Datasource } from "@/models/reports/v3/Reports3Datasource";
import DataHelper from "@/helpers/DataHelper";
import { Reports3Entity } from "@/models/reports/v3/Reports3Entity";
import { Report3ElementFeatures, Report3ElementFeaturesToString } from "@/models/reports/v3/Report3ElementFeatures";
import { AggregationType } from "@/models/enums/AggregationType";
import { AggregationTypeString } from "@/models/enums/AggregationTypeString";
import { TimeRange } from "@/models/enums/TimeRange";
import { AggregationPeriod } from "@/models/enums/AggregationPeriod";
import WidgetHelper from "@/helpers/WidgetHelper";
import StreamOption from "@/models/dashboard/StreamOption";
import ReportPdfHelper from "@/helpers/ReportPdfHelper";
import { useBitpoolAIChatStore } from "./bitpoolAIChat";
import moment from "moment";
import { json2csv, Json2CsvOptions } from "json-2-csv";
import { useAIInsightLibraryStore } from "./aiInsightLibrary";
import { useTagManagerStore } from "./tagManager";
import { NavTreeNodeMinimalWithParents } from "@/models/nav-tree/NavTreeNodeMinimalWithParents";
import { StreamValuesRequest } from "@/models/StreamValuesRequest";
import DateHelper from "@/helpers/DateHelper";
import DataGenerator from "@/helpers/DataGenerator";
import { nextTick } from "vue";

export const useReports3DataStore = defineStore('reports3Data', {
  state: (): Reports3DataState => ({ 
    isLoaded: {},
    requestGuid: {},
    data: {},
    dataRequestBody: {},
    isLoadedAiInsights: {},
    aiRequestGuid: {},
    aiInsights: {},
    aiInsightsRequestCache: {}
  }),
  getters: {
  },
  actions: {
    buildDataIdentifier(element: Reports3ElementEntity,
      elementConfiguration: Reports3ElementConfiguration | null,
      datasource: Reports3Datasource | null,
      report: Reports3Entity | null
    ): string {
      let dataIdentifier = ReportPdfHelper.buildDataIdentifier(element, elementConfiguration, datasource, report);
      return dataIdentifier;
    },
    buildAIDataIdentifier(element: Reports3ElementEntity,
      elementConfiguration: Reports3ElementConfiguration | null,
      datasource: Reports3Datasource | null,
      report: Reports3Entity | null
    ): string {
      let dataIdentifier = ReportPdfHelper.buildAIDataIdentifier(element, elementConfiguration, datasource, report);
      return dataIdentifier;
    },
    async load(element: Reports3ElementEntity,
      elementConfiguration: Reports3ElementConfiguration | null,
      datasource: Reports3Datasource | null,
      report: Reports3Entity | null,
      generate = false
    ) {
      const dataIdentifier = this.buildDataIdentifier(element, elementConfiguration, datasource, report);
      const isTotal = element.Features.includes(Report3ElementFeatures.DataOnlyTotalAggregation);
      const includePreviousPeriod = element.Features.includes(Report3ElementFeatures.IncludePreviousPeriod);
      const bitpoolAIChatStore = useBitpoolAIChatStore();
      const aiInsightLibraryStore = useAIInsightLibraryStore();
      const aiDataIdentifier = this.buildAIDataIdentifier(element, elementConfiguration, datasource, report);
      const isAIElement = element.Features.includes(Report3ElementFeatures.BitpoolAI);
      try {
        if (elementConfiguration && datasource && report) {
          // Load actual data
          const requestBody = DataHelper.wdsToApiRequest(
            datasource.Configuration, 
            datasource.UseReportDRS ? report.DateRange : null,
            isTotal ? "reports3_total" : "reports3",
            undefined,
            generate
          );
          if (includePreviousPeriod) {
            // Build request for previous period
            const previousPeriodStreams: StreamValuesRequest[] = [];
            for (const stream of requestBody.Streams) {
              const previousPeriodRequest: StreamValuesRequest = JSON.parse(JSON.stringify(stream));
              const dates = DateHelper.extractDateFromRequestBody(requestBody);
              const datesBefore = DateHelper.goBackForward(-1, dates[0], dates[1], true);
              const dateFromM = moment(datesBefore[0]);
              const dateToM = moment(datesBefore[1]);
              previousPeriodRequest.Time = {
                From: dateFromM.format("YYYY-MM-DDTHH:mm:ss"),
                To: dateToM.format("YYYY-MM-DDTHH:mm:ss"),
                Range: TimeRange.Custom,
              };
              previousPeriodStreams.push(previousPeriodRequest);
            }
            // Add previous period streams to request body
            previousPeriodStreams.forEach(stream => {
              requestBody.Streams.push(stream);
            });
          }
          
          // Reuse datasource value for different elements.
          let isDataLoaded = false;
          if (typeof this.isLoaded[dataIdentifier] !== "undefined") {
            const previousRequestBody = this.dataRequestBody[dataIdentifier];
            const previousRequestBodyString = JSON.stringify(previousRequestBody);
            const currentRequestBodyString = JSON.stringify(requestBody);
            if (previousRequestBodyString === currentRequestBodyString) {
              let isOk = true;
              let awaitedTime = 0;
              const timeoutMs = 500;
              while (!this.isLoaded[dataIdentifier]) {
                await new Promise(resolve => setTimeout(resolve, timeoutMs));
                awaitedTime += timeoutMs;
                if (awaitedTime >= 120000) {
                  isOk = false;
                  break;
                }
              }
              if (isOk && this.isLoaded[dataIdentifier]) {
                isDataLoaded = true;
              }
            }
          }

          const guid = uuidv4();
          this.isLoadedAiInsights[aiDataIdentifier] = false;
          this.aiRequestGuid[aiDataIdentifier] = guid;
          let responseData: AggregatedDataHighchartsResponse[] | null = null;
          if (isDataLoaded) {
            responseData = this.data[dataIdentifier];
          } else {
            this.requestGuid[dataIdentifier] = guid;
            this.isLoaded[dataIdentifier] = false;
            this.dataRequestBody[dataIdentifier] = requestBody;

            if (generate) {
              await nextTick();
              responseData = DataGenerator.generateData(requestBody);
            } else {
              const url = `rest/BitPool_V2/stream/values/Highcharts`;
              const response = await axios.post<AggregatedDataHighchartsResponse[]>(url, requestBody, {
                headers: {
                  "Content-Type": "application/json",
                },
              });
              responseData = response.data;
            }
            if (this.requestGuid[dataIdentifier] !== guid) {
              return;
            }
          }
          let aiQuestion = "";
          let aiResponse = "";
          if (isAIElement) {
            let personaId = "";
            let prependData = "";
            if (elementConfiguration.FeaturesConfiguration && elementConfiguration.FeaturesConfiguration[Report3ElementFeaturesToString[Report3ElementFeatures.BitpoolAI]]) {
              const config = elementConfiguration.FeaturesConfiguration[Report3ElementFeaturesToString[Report3ElementFeatures.BitpoolAI]];
              personaId = config.PersonaId;

              // Insight library
              if (config.QuestionId && config.QuestionId !== "custom") {
                const question = aiInsightLibraryStore.entities?.find(x => x.Id === config.QuestionId);
                if (question) {
                  aiQuestion = question.Question;
                }
              }
              if (!aiQuestion) {
                aiQuestion = config.CustomQuestion;
              }

              // Prepare prepend data
              const featuresConfiguration = elementConfiguration.FeaturesConfiguration;
              const elemenDataConfiguration: Reports3Datasource = JSON.parse(JSON.stringify(datasource));
              const data: AggregatedDataHighchartsResponse[] = JSON.parse(JSON.stringify(responseData));
              const keyDataStreams = "DataStreams";
              if (featuresConfiguration[keyDataStreams]) {
                let streamOptions: StreamOption[] = [];
                const streamOptionsSource = elemenDataConfiguration.Configuration.streamOptions.filter(x => x.StreamKey);
                const removeDataIndexes: number[] = [];
                const uids = Object.getOwnPropertyNames(featuresConfiguration[keyDataStreams]);
                if (uids.length > 0) {
                  for (let i = 0; i < streamOptionsSource.length; i++) {
                    const dataStream = streamOptionsSource[i];
                    if (dataStream.Uid && featuresConfiguration[keyDataStreams][dataStream.Uid]) {
                      streamOptions.push(dataStream);
                    } else {
                      removeDataIndexes.push(i);
                    }
                  }
                } else {
                  for (let i = 0; i < streamOptionsSource.length; i++) {
                    removeDataIndexes.push(i);
                  }
                }
                
                if (data) {
                  if (removeDataIndexes.length && element.Features.includes(Report3ElementFeatures.IncludePreviousPeriod)) {
                    const removePreviousDataIndexes: number[] = [];
                    for (let i = 0; i < removeDataIndexes.length; i++) {
                      removePreviousDataIndexes.push(removeDataIndexes[i] + streamOptionsSource.length);
                    }
                    removeDataIndexes.push(...removePreviousDataIndexes);
                  }
                  for (let i = removeDataIndexes.length - 1; i >= 0; i--) {
                    data.splice(removeDataIndexes[i], 1);
                  }
                }
                elemenDataConfiguration.Configuration.streamOptions = streamOptions;
              }
              const streamKeys: string[] = [];
              const names: string[] = [];
              elemenDataConfiguration.Configuration.streamOptions.forEach(stream => {
                if (stream.StreamKey) {
                  streamKeys.push(stream.StreamKey);
                  names.push(stream.Label ? stream.Label : stream.Name ? stream.Name : stream.StreamKey);
                }
              });

              const tagManagerStore = useTagManagerStore();
              let tagsPrepend = "";
              const tagsWithParents = await tagManagerStore.getStreamsTagsWithParents(streamKeys);
              let index = 0;
              elemenDataConfiguration.Configuration.streamOptions.forEach(stream => {
                if (stream.StreamKey) {
                  if (stream.Params) {
                    let name = names[index];
                    let item: NavTreeNodeMinimalWithParents | null | undefined = tagsWithParents[stream.StreamKey];
                    while (item) {
                      const streamTags = (item ? item.Tags : []).filter(x => !x.includes("Ref=") && !x.endsWith("Ref"));
                      if (streamTags.length) {
                        tagsPrepend += `Project haystack tags applied to '${name}': ${streamTags.join(", ")}\n`;
                      }
                      if (item.Parent) {
                        tagsPrepend += `'${name}' is contained by '${item.Parent.Name}'\n`;
                        name = item.Parent.Name;
                      }
                      item = item.Parent;
                    }
                  }
                  index++;
                }
              });

              const datesSet = new Set<number>();
              data.forEach((streamData) => {
                if (streamData.Data && streamData.Data.length) {
                  streamData.Data.forEach(element => {
                    if (typeof element.x !== "undefined" && element.x !== null) {
                      datesSet.add(element.x);
                    }
                  });
                }
              });
              const dates = Array.from(datesSet);
              dates.sort();
              const rows: Record<string, any> = {};
              rows["header"] = { date: "Timestamp" };dates.forEach(date => {
                const row: any = {
                  date: moment(date).utc().format("YYYY-MM-DD HH:mm:ss")
                };
                data.forEach((stream, index) => {
                  row[`field_${index}`] = null;
                  rows["header"][`field_${index}`] = names[index] ?? stream.StreamKey;
                });
                rows[`${date}`] = row;
              });
              data.forEach((streamData, index) => {
                if (!streamData.Error && streamData.Data && streamData.Data.length) {
                  streamData.Data.forEach(element => {
                    const key = `${element.x}`;
                    if (rows[key]) {
                      const field = `field_${index}`;
                      rows[key][field] = typeof element.y === "number" ? parseFloat(element.y.toFixed(2)) : element.y;
                    }
                  });
                }
              });
              const tableValues: any[] = [];
              for (const key in rows) {
                tableValues.push(rows[key]);
              }

              const options: Json2CsvOptions = {
                delimiter: {
                  wrap: '"',
                  field: ',',
                  eol: '\n'
                },
                emptyFieldValue: "",
                prependHeader: false,
                sortHeader: false,
                excelBOM: false,
                trimHeaderFields: true,
                trimFieldValues: true
              };
              const csvData = await json2csv(tableValues, options);
              prependData = 
                `Data in csv format.\nFirst column is Timestamp in format: YYYY-MM-DD HH:mm:ss.\nOther columns represent data points values.\n${tagsPrepend}Data for analyze:\n${csvData}`;
            }
            const aiInsightsRequest = `${personaId};${aiQuestion};${prependData}`;
            if (this.aiInsightsRequestCache[aiInsightsRequest] && 
              this.aiInsightsRequestCache[aiInsightsRequest] === aiInsightsRequest &&
              this.aiInsights[aiDataIdentifier]
            ) {
              aiResponse = this.aiInsights[aiDataIdentifier][1];
              this.isLoadedAiInsights[aiDataIdentifier] = true;
            } else {
              this.aiInsights[aiDataIdentifier] = null;
              aiResponse = await bitpoolAIChatStore.generateResponseForReport(
                personaId, 
                prependData,
                aiQuestion,
                generate // true - stub
              );
              if (this.aiRequestGuid[aiDataIdentifier] === guid) {
                this.aiInsights[aiDataIdentifier] = [aiQuestion, aiResponse];
                this.isLoadedAiInsights[aiDataIdentifier] = true;
              }
            }
          } else {
            if (this.aiRequestGuid[aiDataIdentifier] === guid) {
              this.aiInsights[aiDataIdentifier] = null;
              this.isLoadedAiInsights[aiDataIdentifier] = true;
            }
          }
          if (!isDataLoaded && this.requestGuid[dataIdentifier] === guid) {
            this.data[dataIdentifier] = responseData;
            this.isLoaded[dataIdentifier] = true;
          }
        } else {
          // Create data stub for testing purposes
          const guid = uuidv4();
          this.requestGuid[dataIdentifier] = guid;
          this.isLoaded[dataIdentifier] = false;
          if (isAIElement) {
            const aiResponse = await bitpoolAIChatStore.generateResponseForReport(
              "personaId", 
              "prependData",
              "currentMessage",
              true // stub
            );
            this.aiInsights[aiDataIdentifier] = ["currentMessage", aiResponse];
          }
          this.data[dataIdentifier] = this.buildStubData(isTotal, includePreviousPeriod, element.DataStreamsMaxCount);
          this.isLoadedAiInsights[aiDataIdentifier] = true;
          this.isLoaded[dataIdentifier] = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load datasource data",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.data[dataIdentifier] = null;
        this.isLoaded[dataIdentifier] = true;
        this.aiInsights[aiDataIdentifier] = null;
        this.isLoadedAiInsights[aiDataIdentifier] = true;
      }
    },
    buildStubDataConfiguration(dataStreamsMaxCount: number): Reports3Datasource {
      const streamsCount = dataStreamsMaxCount === 1 ? 1 : 2;
      const result: Reports3Datasource = {
        Uid: "stub-1",
        Name: "Stub Data",
        UseReportDRS: false,
        Configuration: {
          streamOptions: [],
          aggType: undefined,
          showNullValues: 1,

          rangePreset: TimeRange.Custom,
          rangePresetHolder: TimeRange.Today,
          aggPeriod: AggregationPeriod.Daily,
          autoAggPeriod: true,
          startDate: moment(1729641600000).format("YYYY-MM-DD"),
          startTime: "00:00",
          endDate: moment(1729641600000).add(7, "day").format("YYYY-MM-DD"),
          endTime: "00:00",
        }
      };
      
      const widgetDescription = WidgetHelper.getWidget("reports3", false);
      if (widgetDescription) {
        for (let i = 0; i < streamsCount; i++) {
          const so: StreamOption = JSON.parse(JSON.stringify(widgetDescription.defaultStreamOptions));
          so.StreamKey = `0000000000-0000-0000-0000-00000000000${i}`;
          so.Name = `Stream ${i}`;
          so.Label = `Stream ${i}`;
          result.Configuration.streamOptions.push(so);
        }
      }
      return result;
    },
    buildStubData(isTotal: boolean, includePreviousPeriod: boolean, dataStreamsMaxCount: number): AggregatedDataHighchartsResponse[] {
      const data: AggregatedDataHighchartsResponse[] = [];
      const previousData: AggregatedDataHighchartsResponse[] = [];
      const streamsCount = dataStreamsMaxCount === 1 ? 1 : 2;
      for (let i = 0; i < streamsCount; i++) {
        if (isTotal) {
          const y2 = Math.random() * 100000;
          const y1 = y2 - Math.random() * 10000;
          data.push(
            {
              StreamKey: `0000000000-0000-0000-0000-00000000000${i}`,
              Data: [
                {
                  x: 1729641600000,
                  y: y2 - y1,
                  y1: y1,
                  y2: y2,
                  calculated: false
                }
              ],
              Error: null,
              StreamValuesRequest: {
                StreamKey: `0000000000-0000-0000-0000-00000000000${i}`,
                AggregationType: AggregationType.Last,
                AggregationTypeString: AggregationTypeString.Last,
                Multiplier: 1.0,
                PostProcessIfAvailable: false,
                TimeIntervals: null,
                Time: null,
                AggregationPeriod: null
              }
            }
          );
          if (includePreviousPeriod) {
            const y2p = Math.random() * 100000;
            const y1p = y2p - Math.random() * 10000;
            previousData.push(
              {
                StreamKey: `0000000000-0000-00000000000${i}`,
                Data: [
                  {
                    x: 1729641600000 - 86400000,
                    y: y2p - y1p,
                    y1: y1p,
                    y2: y2p,
                    calculated: false
                  }
                ],
                Error: null,
                StreamValuesRequest: {
                  StreamKey: `0000000000-0000-00000000000${i}`,
                  AggregationType: AggregationType.Last,
                  AggregationTypeString: AggregationTypeString.Last,
                  Multiplier: 1.0,
                  PostProcessIfAvailable: false,
                  TimeIntervals: null,
                  Time: null,
                  AggregationPeriod: null
                }
              }
            );
          }
        } else {
          data.push(
            {
              StreamKey: `0000000000-0000-0000-0000-00000000000${i}`,
              Data: [
              ],
              Error: null,
              StreamValuesRequest: {
                StreamKey: `0000000000-0000-0000-0000-00000000000${i}`,
                AggregationType: AggregationType.Last,
                AggregationTypeString: AggregationTypeString.Last,
                Multiplier: 1.0,
                PostProcessIfAvailable: false,
                TimeIntervals: null,
                Time: null,
                AggregationPeriod: null
              }
            }
          );
          if (includePreviousPeriod) {
            previousData.push(
              {
                StreamKey: `0000000000-0000-00000000000${i}`,
                Data: [
                ],
                Error: null,
                StreamValuesRequest: {
                  StreamKey: `0000000000-0000-00000000000${i}`,
                  AggregationType: AggregationType.Last,
                  AggregationTypeString: AggregationTypeString.Last,
                  Multiplier: 1.0,
                  PostProcessIfAvailable: false,
                  TimeIntervals: null,
                  Time: null,
                  AggregationPeriod: null
                }
              }
            );
          }
          for (let j = 0; j < 7; j++) {
            const x = 1729641600000 + (j * 86400000);
            const y2 = Math.random() * 100000;
            const y1 = y2 - Math.random() * 10000;
            data[i].Data.push({
              x: x,
              y: y2 - y1,
              y1: y1,
              y2: y2,
              calculated: false
            });

            if (includePreviousPeriod) {
              previousData[i].Data.push({
                x: 1729641600000 - (7 - j) * 86400000,
                y: (y2 - y1) * 0.9,
                y1: y1 * 0.9,
                y2: y2 * 0.9,
                calculated: false
              });
            }
          }
        }
      }
      previousData.forEach(pd => {
        data.push(pd);
      });
      return data;
    }
  },
})
