export enum ScriptAdditionalParameterType {
  String,
  Number,
  Boolean,
  Color,
  Image,
  StringMultiLine,
  FontFamily,
  FontSize,
  FontWeight,
  FontStyle,
  TextAlign,
  GroupTitle,
  Divider,
  AlignVertical,
  AlignHorizontal,
  ImageSizeBackground,
  ImageSize
}