<template>
  <draggable
    tag="div"
    :list="items"
    handle=".handle"
    @end="dropEvent"
    :group="{ name: 'elements' }"
    :animation="150"
    :fallbackOnBody="true"
    :swapThreshold="0.65"
    :invertSwap="true"
    :direction="getSortDirection"
    :disabled="!reports3Store.editMode"
    ghostClass="dragging"
    class="bp-grid droppable-area"
    item-key="Uid"
  >
    <template #item="{ element }">
      <div :class="`bp-col-${element.Size}`">
        <!-- Element -->
        <div
          v-if="element.Role === reports3ItemRole.Element"
          class="report-element"
          :class="`${reports3Store.editMode && element.Uid === reports3Store.dataOneSelectedElementConfiguration?.Uid ? 'is-selected' : ''}`"
          :id="`reportConstructorElementContainer-${element.Uid}`"
        >
          <div 
            v-if="reports3Store.editMode"
            class="report-element-head"
          >
            <div class="report-element-head-inner handle" @click="selectElement(element)"></div>
            <Button
              v-if="reports3Store.isCompactMode"
              v-tippy="'Edit'"
              @click="openEditDialog($event, element)"
              class="p-button-half-white"
            >
              <ReportEditSvg/>
            </Button>
            <Button
              v-tippy="'Delete'"
              @click="deleteItem($event, element)"
              class="p-button-half-white"
            >
              <ReportDeleteSvg/>
            </Button>
            <Button
              v-tippy="'More Actions'"
              @click="showAdditionalMenu($event, element)"
              class="p-button-half-white"
            >
              <ReportMoreActionsSvg/>
            </Button>
          </div>
          <ReportsHtmlElementView
            v-if="getElement(element.ElementId)"
            :element="getElement(element.ElementId)" 
            :configuration="element"
            :report="report"
            :mode="mode"
          />
          <span v-else>Element is missing</span>
        </div>

        <!-- Grid -->
        <div
          v-else-if="element.Role === reports3ItemRole.Grid"
          class="report-grid"
          :class="`${reports3Store.editMode && element.Uid === reports3Store.dataOneSelectedElementConfiguration?.Uid ? 'is-selected' : ''}`"
          :id="`reportConstructorGridContainer-${element.Uid}`"
        >
          <div
            v-if="reports3Store.editMode"
            class="report-grid-head"
          >
            <div class="report-grid-head-inner handle" @click="selectElement(element)"></div>
            <Button
              v-tippy="'Add Grid'"
              @click="addGrid($event, element)"
              class="p-button-half-white"
            >
              <AddReportGridSvg/>
            </Button>
            <Button
              v-tippy="'Add Element'"
              @click="openElementsDialog($event, element)"
              class="p-button-half-white"
            >
              <AddReportElementSvg/>
            </Button>
            <Button
              v-if="reports3Store.isCompactMode"
              v-tippy="'Edit'"
              @click="openEditDialog($event, element)"
              class="p-button-half-white"
            >
              <ReportEditSvg/>
            </Button>
            <Button
              v-tippy="'Delete'"
              @click="deleteItem($event, element)"
              class="p-button-half-white"
            >
              <ReportDeleteSvg/>
            </Button>
            <Button
              v-tippy="'More Actions'"
              @click="showAdditionalMenu($event, element)"
              class="p-button-half-white"
            >
              <ReportMoreActionsSvg/>
            </Button>
          </div>
          <ReportsHtmlGridView v-model="element.Items" :report="report" :mode="mode"/>
        </div>
        
        <Menu ref="additionalMenu" :model="additionalMenuItems" :popup="true" />
      </div>
    </template>
  </draggable>

  <ReportsSelectElementDialogView
    v-model="displayElementsDialog"
    @elementSelected="addElement"
  />
</template>

<script lang="ts">
import { Component, Model, Prop, Ref, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import { MenuItem } from "primevue/menuitem";
import { Reports3ElementConfiguration } from "@/models/reports/v3/Reports3ElementConfiguration";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";
import ReportsHtmlElementView from "@/components/views/reports/ReportsHtmlElementView.vue";
import { Reports3ItemRole } from "@/models/reports/v3/Reports3ItemRole";
import { v4 as uuidv4 } from "uuid";
import { useReports3Store } from "@/stores/reports3";
import ReportsSelectElementDialogView from "@/components/views/reports/ReportsSelectElementDialogView.vue";
import draggable from "vuedraggable";
import AddReportElementSvg from "@/components/svg/AddReportElementSvg.vue";
import AddReportGridSvg from "@/components/svg/AddReportGridSvg.vue";
import ReportMoreActionsSvg from "@/components/svg/ReportMoreActionsSvg.vue";
import ReportDeleteSvg from "@/components/svg/ReportDeleteSvg.vue";
import ReportEditSvg from "@/components/svg/ReportEditSvg.vue";
import { Reports3Entity } from "@/models/reports/v3/Reports3Entity";
import ConfirmationService from "@/services/ConfirmationService";
import { nextTick } from "vue";
import { useReports3DataStore } from "@/stores/reports3Data";
import ToastService from "@/services/ToastService";

@Component({
  components: {
    Button,
    Menu,
    ReportsHtmlElementView,
    ReportsSelectElementDialogView,
    draggable,
    AddReportElementSvg,
    AddReportGridSvg,
    ReportMoreActionsSvg,
    ReportDeleteSvg,
    ReportEditSvg
  },
  directives: {
  }
})
class ReportsHtmlGridView extends Vue {
  @Model items!: Reports3ElementConfiguration[];
  @Prop({ required: false, default: null }) report!: Reports3Entity | null;
  @Prop({ required: false, default: "reports" }) mode!: string;

  get reports3Store() {
    return useReports3Store(this.mode);
  }
  reports3DataStore = useReports3DataStore();

  getElement(id: string): Reports3ElementEntity | undefined {
    if (this.reports3Store.dataOneElements?.length) {
      const element = this.reports3Store.dataOneElements.find((x) => x.Id === id);
      return element;
    }
    return undefined;
  }

  selectElement(elementConfiguration: Reports3ElementConfiguration): void {
    this.reports3Store.selectElement(elementConfiguration);
  }

  deleteItem(event: Event, elementConfiguration: Reports3ElementConfiguration): void {
    const message = `Are you sure you want to delete ${elementConfiguration.ElementId ? 'element' : 'grid'}?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        if (elementConfiguration) {
          const index = this.items.findIndex((x) => x.Uid === elementConfiguration.Uid);
          if (index !== -1) {
            this.items.splice(index, 1);
          }
          if (this.reports3Store.dataOneSelectedElementConfiguration?.Uid === elementConfiguration.Uid) {
            this.reports3Store.dataOneSelectedElementConfiguration = null;
          }
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  async addGrid(event: Event, elementConfiguration: Reports3ElementConfiguration): Promise<void> {
    if (elementConfiguration.Role === Reports3ItemRole.Grid) {
      const item = this.reports3Store.createGridConfiguration();
      if (!elementConfiguration.Items) {
        elementConfiguration.Items = [];
      }
      elementConfiguration.Items.push(item);
      this.reports3Store.dataOneSelectedElementConfiguration = item;
      // Scroll to the new element
      await nextTick();
      const id = `reportConstructorGridContainer-${item.Uid}`;
      const targetScrollElement = document.getElementById(id);
      if (targetScrollElement) {
        targetScrollElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  displayElementsDialog = false;
  selectedElementConfiguration: Reports3ElementConfiguration | null = null;

  openElementsDialog(event: Event, elementConfiguration: Reports3ElementConfiguration): void {
    this.selectedElementConfiguration = elementConfiguration;
    this.displayElementsDialog = true;
  }

  async addElement(element: Reports3ElementEntity): Promise<void> {
    if (this.selectedElementConfiguration?.Role === Reports3ItemRole.Grid) {
      const item = this.reports3Store.createElementConfiguration(element);
      if (!this.selectedElementConfiguration.Items) {
        this.selectedElementConfiguration.Items = [];
      }
      this.selectedElementConfiguration.Items.push(item);
      this.reports3Store.dataOneSelectedElementConfiguration = item;
      this.displayElementsDialog = false;
      // Scroll to the new element
      await nextTick();
      const id = `reportConstructorElementContainer-${item.Uid}`;
      const targetScrollElement = document.getElementById(id);
      if (targetScrollElement) {
        targetScrollElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  reports3ItemRole = Reports3ItemRole;

  dropEvent(event: any): void {
    // nothing
  }

  getSortDirection(event: DragEvent, target: HTMLElement, dragEl: HTMLElement): string {
    // console.log(event);
    // console.log(target);
    // console.log(dragEl);
    if (target && target.classList.contains("grid")) {
      // drag to grid
      return "vertical";
    } else {
      // drag to widget
      return "horizontal";
    }
  }

  openEditDialog(event: Event, elementConfiguration: Reports3ElementConfiguration): void {
    this.selectElement(elementConfiguration);
    if (this.reports3Store.isCompactMode) {
      this.reports3Store.displayConfigurationDialog = true;
    }
  }

  // #region additional actions
  @Ref() readonly additionalMenu!: Menu;

  showAdditionalMenu(event: Event, elementConfiguration: Reports3ElementConfiguration): void {
    this.selectElement(elementConfiguration);
    this.additionalMenu.toggle(event);
  }
  
  get additionalMenuItems(): MenuItem[] {
    const result: MenuItem[] = [];
    if (this.reports3Store.dataOneSelectedElementConfiguration) {
      result.push({
          label: 'Cut',
          icon: undefined,
          command: () => {
            if (this.reports3Store.dataOneSelectedElementConfiguration) {
              this.reports3Store.cutOrCopy = false;
              this.reports3Store.copiedConfiguration = this.reports3Store.dataOneSelectedElementConfiguration;
            }
          }
        });
        result.push({
          label: 'Copy',
          icon: undefined,
          command: () => {
            if (this.reports3Store.dataOneSelectedElementConfiguration) {
              this.reports3Store.cutOrCopy = true;
              this.reports3Store.copiedConfiguration = this.reports3Store.dataOneSelectedElementConfiguration;
            }
          }
        });
        if (this.reports3Store.dataOneSelectedElementConfiguration.Role === Reports3ItemRole.Grid) {
          result.push({
            label: 'Paste',
            icon: undefined,
            command: () => {
              if (this.reports3Store.dataOneSelectedElementConfiguration?.Items && this.reports3Store.copiedConfiguration) {
                if (!this.reports3Store.cutOrCopy) {
                  // cut
                  function canMoveHere(item: Reports3ElementConfiguration, parent: Reports3ElementConfiguration): boolean {
                    if (item.Uid === parent.Uid) {
                      return false;
                    }
                    if (item.Items) {
                      for (const child of item.Items) {
                        const canMove = canMoveHere(child, parent);
                        if (!canMove) {
                          return false;
                        }
                      }
                    }
                    return true;
                  }
                  const newParent = this.reports3Store.dataOneSelectedElementConfiguration;
                  const copiedItem = this.reports3Store.copiedConfiguration;
                  let canMove = canMoveHere(copiedItem, newParent);
                  if (!canMove) {
                    ToastService.showToast("error", "Report", "Cannot move report element here", 5000);
                    return;
                  }
                }
                this.pasteConfiguration(this.reports3Store.dataOneSelectedElementConfiguration.Items, this.reports3Store.copiedConfiguration, this.reports3Store.cutOrCopy);
              }
            },
            disabled: !this.reports3Store.copiedConfiguration
          });
        }
        result.push({
          label: 'Duplicate',
          icon: undefined,
          command: () => {
            if (this.reports3Store.dataOneSelectedElementConfiguration) {
              this.pasteConfiguration(this.items,this.reports3Store.dataOneSelectedElementConfiguration, true);
            }
          }
        });
    }
    return result;
  }

  regenerateUids(source: Reports3ElementConfiguration): void {
    source.Uid = uuidv4();
    if (source.Items) {
      source.Items.forEach((x) => this.regenerateUids(x));
    }
  }

  findParent(source: Reports3ElementConfiguration, items: Reports3ElementConfiguration[]): Reports3ElementConfiguration[] | null {
    for (const item of items) {
      if (item.Uid === source.Uid) {
        return items;
      }
      if (item.Items) {
        const result = this.findParent(source, item.Items);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  pasteConfiguration(destination: Reports3ElementConfiguration[], source: Reports3ElementConfiguration, cutOrCopy: boolean): void {
    // cutOrCopy: false - cut, true - copy
    if (cutOrCopy) {
      // copy
      const newItem = JSON.parse(JSON.stringify(source)) as Reports3ElementConfiguration;
      this.regenerateUids(newItem);
      destination.push(newItem);
      this.reloadDataWithChild(newItem);
    } else {
      // cut
      const newItem = source;
      if (this.report?.Items) {
        const parent = this.findParent(source, this.report.Items);
        if (parent) {
          const index = parent.findIndex((x) => x.Uid === source.Uid);
          if (index !== -1) {
            parent.splice(index, 1);
          }
        }
        destination.push(newItem);
      }
    }
    // #endregion additional actions
  }

  reloadDataWithChild(elementConfiguration: Reports3ElementConfiguration): void {
    if (elementConfiguration.Role === Reports3ItemRole.Grid) {
      if (elementConfiguration.Items) {
        elementConfiguration.Items.forEach((x) => this.reloadDataWithChild(x));
      }
    } else {
      this.reloadData(elementConfiguration);
    }
  }

  reloadData(elementConfiguration: Reports3ElementConfiguration): void {
    const element = this.getElement(elementConfiguration.ElementId);
    const datasource = this.reports3Store.dataOne?.Datasources.find(x => x.Uid === elementConfiguration.DatasourceId);
    if (element && datasource) {
      this.reports3DataStore.load(element, elementConfiguration, datasource, this.reports3Store.dataOne, this.mode === "templates");
    }
  }
}

export default ReportsHtmlGridView;
</script>