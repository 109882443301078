<template>
  <div>
    <Dialog 
      header="Bitpool AI" 
      v-model:visible="displayBitpoolAIDialog" 
      :modal="true" 
      :style="{width: '72rem'}" 
      class="openai-widget-dialog" 
      @hide="onBitpoolAIHide"
    >
      <BitpoolAIView :widget="selectedWidget"/>
      <template #header>
        <div class="w-full flex-shrink-0">
          <span class="p-dialog-title">
            <img src="/assets/openai.svg" alt="Bitpool AI logo" />
            <span>Bitpool AI</span>
          </span>

          <div>
            <div class="flex column-gap-3 justify-content-between sm:justify-content-start">
              <div class="ai-skills-dropdown">
                <label for="AiSkillsDropdown">Skill</label>
                <div>
                  <Dropdown
                    v-if="availablePersonas.length > 1"
                    v-model="bitpoolAIChatState.selectedAI"
                    :options="availablePersonas"
                    optionValue="Id" 
                    optionLabel="Name"
                    placeholder="Select AI"
                    id="AiSkillsDropdown"
                    @change="onSelectedAIChange"
                  />
                </div>
              </div>
              <div class="ai-voice-button">
                <Button
                  v-if="!aiSpeechStore.isGloballyDisabled"
                  label="Voice" 
                  icon="pi pi-volume-up text-xl" 
                  class="flex-shrink-0 p-button-icon-only openai-voice"
                  :class="speechSynthesisIsEnabled ? '' : 'p-button-light-secondary'" 
                  @click="toggleSpeechSynthesis"
                />
              </div>
            </div>
            <Accordion class="openai-more-data-accordion flex-shrink-0">
              <AccordionTab header="See data">
                <div class="openai-more-data">{{ bitpoolAIChatState.prependData }}</div>
              </AccordionTab>
            </Accordion>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="openai-comments-add mb-0 block w-full">
          <div>
            <Textarea 
              v-model="newMessage" 
              :disabled="bitpoolAIChatState.inProgress" 
              placeholder="Hey Bitpool" 
              style="white-space: pre-wrap;" 
              :autoResize="true" 
              class="w-full"
              @keydown.enter="($event) => {
                if ($event.shiftKey) {
                  return;
                }
                $event.preventDefault();
                newMessage.trim();
                sendMessage();
              }"
            />
            <div class="inline-flex md:flex-column">
              <Button 
                @click="toggleMic" 
                :disabled="!isSRSupported || bitpoolAIChatState.inProgress" 
                label="Speech" 
                :icon="recognitionInProgress && (speechRecognitionStore.startedManually || speechRecognitionStore.commandRecognized) ? 'pi pi-stop-circle text-xl' : 'pi pi-microphone text-xl'" 
                class="flex-shrink-0 p-button-lg p-button-icon-only p-button-text p-button-secondary mr-0 openai-speech" />
              <Button 
                @click="sendMessage" 
                :disabled="bitpoolAIChatState.inProgress || !newMessage" 
                label="Add" 
                icon="pi pi-send text-xl" 
                class="flex-shrink-0 p-button-lg p-button-icon-only p-button-text p-button-primary" />
            </div>
          </div>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { SpaceWidgetConfig } from "@/models/dashboard/SpaceWidgetConfig";
import RootState from "@/store/states/RootState";
import { useBitpoolAIChatStore } from "@/stores/bitpoolAIChat";
import { useAISpeechStore } from "@/stores/aiSpeech";
import BitpoolAIChatState from "@/stores/states/BitpoolAIChatState";
import { Component, Model, Prop, Vue, Watch } from "vue-facing-decorator";
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Textarea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import BitpoolAIView from "./BitpoolAIView.vue";
import { useAIPersonaStore } from "@/stores/aiPersona";
import { AIPersonaEntity } from "@/models/bitpool-ai/AIPersonaEntity";
import AuthState from "@/store/states/AuthState";
import { useSystemStore } from "@/stores/system";
import { useSpeechRecognitionStore } from "@/stores/speechRecognition";

@Component({
  components: {
    Button,
    Dialog,
    Textarea,
    Dropdown,
    Accordion,
    AccordionTab,
    BitpoolAIView
  },
})
class BitpoolAIDialogView extends Vue {
  @Model({ required: true }) displayBitpoolAIDialog!: boolean;
  @Prop({ required: true }) dashboardId!: string;
  @Prop({ required: true }) selectedWidget!: SpaceWidgetConfig | null;

  bitpoolAIChatStore = useBitpoolAIChatStore();
  aiSpeechStore = useAISpeechStore();
  aiPersonaStore = useAIPersonaStore();
  systemStore = useSystemStore();
  speechRecognitionStore = useSpeechRecognitionStore();

  get rootState(): RootState {
    return this.$store.state;
  }

  get auth(): AuthState {
    return this.$store.state.auth;
  }
  
  get bitpoolAIChatState(): BitpoolAIChatState {
    return this.bitpoolAIChatStore;
  }

  unmounted() {
    this.speechRecognitionStore.stopWaitingForSpeech();
    this.speechRecognitionStore.stop();
  }

  @Watch('displayBitpoolAIDialog', { immediate: false, deep: false })
  onDisplayBitpoolAIDialogChanged(): void {
    if (this.displayBitpoolAIDialog) {
      const selectedPersona = this.auth.userSettings?.selectedPersona ?? "";
      if (selectedPersona) {
        this.bitpoolAIChatStore.selectedAI = selectedPersona;
      } else {
        const defaultPersona: AIPersonaEntity | undefined = this.aiPersonaStore.entities ? this.aiPersonaStore.entities.find(x => x.Enabled && x.Default) : undefined;
        this.bitpoolAIChatStore.selectedAI = defaultPersona ? defaultPersona.Id : ""; 
      }
      const isSpeechEnabled = this.auth.userSettings?.isEnabledSpeech ?? false;
      this.aiSpeechStore.speechSynthesisIsEnabled = isSpeechEnabled;

      this.speechRecognitionStore.startWaitingForSpeech();
    } else {
      this.speechRecognitionStore.stopWaitingForSpeech();
    }
  }

  onSelectedAIChange(): void {
    this.bitpoolAIChatStore.cleanHistory();
    this.aiSpeechStore.stopSpeak();
  }

  get availablePersonas(): AIPersonaEntity[] {
    const result: AIPersonaEntity[] = this.aiPersonaStore.entities ? this.aiPersonaStore.entities.filter(x => x.Enabled) : [];
    result.push({ 
      Id: "personal", 
      Name: "Personal",
      Model: "",
      Personality: "",
      Enabled: true,
      Default: false,
      Created: new Date(),
      Updated: new Date(),
      CreatedBy: "",
      UpdatedBy: "",
      Questions: [],
      Voice: "",
      Endpoint: "openai-chat"
    });
    return result;
  }

  get newMessage(): string {
    return this.bitpoolAIChatState.newMessage;
  }

  set newMessage(value: string) {
    this.bitpoolAIChatState.newMessage = value;
  }

  async sendMessage(): Promise<void> {
    this.speechRecognitionStore.stop();
    if (this.newMessage) {
      await this.bitpoolAIChatStore.sendMessage();
    }
  }

  get isSRSupported(): boolean {
    return  this.speechRecognitionStore.isSupported;
  }

  get recognitionInProgress(): boolean {
    return this.speechRecognitionStore.recognitionInProgress;
  }

  toggleMic(): void {
    if (this.isSRSupported) {
      if (this.recognitionInProgress) {
        this.sendMessage();
      } else {
        this.aiSpeechStore.stopSpeak();
        this.speechRecognitionStore.start(this.newMessage);
      }
    }
  }

  get speechSynthesisIsEnabled(): boolean {
    return this.aiSpeechStore.speechSynthesisIsEnabled;
  }

  toggleSpeechSynthesis(): void {
    this.aiSpeechStore.toggleSpeechSynthesis();
  }

  onBitpoolAIHide(): void {
    this.speechRecognitionStore.stop();
    const devStub = this.bitpoolAIChatStore.devStub;
    this.bitpoolAIChatStore.$reset();
    this.bitpoolAIChatStore.devStub = devStub;
    this.aiSpeechStore.stopSpeak();
  }
}

export default BitpoolAIDialogView;
</script>