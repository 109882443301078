export enum Report3ElementFeatures {
  DataOnlyTotalAggregation,
  DataSeriesType,
  DataSeriesTypeStacked,
  YAxis,
  BitpoolAI,
  DataStreamsHide,
  IncludePreviousPeriod,
  DataGroups
}

export const Report3ElementFeaturesToString = {
  [Report3ElementFeatures.DataOnlyTotalAggregation]: 'DataOnlyTotalAggregation',
  [Report3ElementFeatures.DataSeriesType]: 'DataSeriesType',
  [Report3ElementFeatures.DataSeriesTypeStacked]: 'DataSeriesTypeStacked',
  [Report3ElementFeatures.YAxis]: 'YAxis',
  [Report3ElementFeatures.BitpoolAI]: 'BitpoolAI',
  [Report3ElementFeatures.DataStreamsHide]: 'DataStreamsHide',
  [Report3ElementFeatures.IncludePreviousPeriod]: 'IncludePreviousPeriod',
  [Report3ElementFeatures.DataGroups]: 'DataGroups'
};

export const ElementFeaturesTitles: [Report3ElementFeatures, string][] = [
  [Report3ElementFeatures.DataOnlyTotalAggregation, "Only Total Aggregation"],
  [Report3ElementFeatures.DataSeriesType, "Series Type"],
  [Report3ElementFeatures.DataSeriesTypeStacked, "Series Type (Stacked)"],
  [Report3ElementFeatures.YAxis, "Y-Axis"],
  [Report3ElementFeatures.BitpoolAI, "Bitpool AI"],
  [Report3ElementFeatures.DataStreamsHide, "Hide Streams"],
  [Report3ElementFeatures.IncludePreviousPeriod, "Include Previous Period"],
  [Report3ElementFeatures.DataGroups, "Data Groups"]
];