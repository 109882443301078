<template>
  <div v-if="editRecord">
    <BlockUI :blocked="updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="(updateInProgress) ? 'blockui-blocked' : ''">
      <div class="formgrid grid">
        <div class="field col-12">
          <div class="flex align-items-center">
            <InputSwitch 
              inputId="editRecordEnabled"
              v-model="editRecord.Enabled"
              class="vertical-align-top"
            />
            <label for="editRecordEnabled" class="mb-0 ml-2">Enabled</label>
          </div>
        </div>
        <div class="field col-12 md:col-6">
          <label for="editRecordName">Name</label>
          <div>
            <InputText 
              id="editRecordName" 
              class="inputfield w-full"
              type="text" 
              v-model="editRecord.Name"
            />
          </div>
        </div>
        <div class="field col-12 md:col-6">
          <label for="editRecordTimezone">Timezone</label>
          <div>
            <Dropdown 
              inputId="editRecordTimezone"
              v-model="editRecord.Timezone" 
              :options="timezones" 
              optionValue="Id" 
              optionLabel="DisplayName" 
              placeholder="Select a Timezone" 
              :filter="true" 
              filterPlaceholder="Find Timezone" 
              class="w-full"
            />
      
          </div>
        </div>
        <div class="field col-12 mb-0">
          <CronEditorView
            v-model="editRecord.CronExpression"
          />
        </div>
        <div class="field col-12">
          <label for="editRecordRecipients">Recipients</label>
          <div>
            <MultiSelect
              inputId="editRecordRecipients"
              v-model="editRecord.Recipients" 
              :loading="!organisationUsersStore.data[organisationIdStr]?.isLoaded || !notificationGroupStore.isLoaded" 
              :options="emailsAndGroups"
              placeholder="Select Recipients"
              optionLabel="label" 
              optionValue="value"
              optionGroupLabel="label" 
              optionGroupChildren="items"
              display="chip"
              :filter="true"
              filterPlaceholder="Find Recipients"
              class="p-multiselect-multiline inputfield w-full"
            >
              <template #optiongroup="slotProps">
                <div class="flex align-items-center font-bold">
                  <i class="pi pi-folder font-bold mr-2 flex-shrink-0"></i>
                  <div class="flex-auto">{{ slotProps.option.label }}</div>
                </div>
              </template>
              <template #option="slotProps">
                <Chip 
                  :label="slotProps.option.label"
                  :style="getChipStyles(slotProps.option)"
                  :class="slotProps.option.color ? 'chip-colored' : ''"
                />
              </template>
              <template #value="slotProps">
                <tamplate v-if="!slotProps.value.length">
                  {{ slotProps.placeholder }}
                </tamplate>
                <template v-for="(item, index) in slotProps.value" :key="`${item}-${index}`">
                  <Chip 
                    :label="getLabelByValue(item)"
                    :style="getChipStylesByValue(item)"
                    :class="hasColorByValue(item) ? 'chip-colored' : ''"
                    removable
                    @remove="slotProps.value.splice(index, 1)"
                  />
                </template>
              </template>
            </MultiSelect>

          </div>
        </div>
        <div class="field col-12 mb-0" v-if="!hideReports">
          <label for="editRecordReports">Reports</label>
          <div>
            <MultiSelect
              inputId="editRecordReports"
              v-model="editRecord.Reports"
              :options="reports3Store.dataLight"
              :loading="!reports3Store.isLoadedLight"
              placeholder="Select Reports"
              display="chip"
              :filter="true"
              optionValue="Id"
              optionLabel="Name"
              class="p-multiselect-multiline inputfield w-full"
            />
          </div>
        </div>
      </div>

      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
    </BlockUI>
  </div>
</template>

<script lang="ts">
import { Component, Model, Prop, Vue } from "vue-facing-decorator";
import ProgressSpinner from 'primevue/progressspinner';
import BlockUI from 'primevue/blockui';
import InputSwitch from "primevue/inputswitch";
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Chip from "primevue/chip";
import InputText from 'primevue/inputtext';
import { AllUserData } from "@/models/user/AllUserData";
import { useReports3SchedulesStore } from "@/stores/reports3Schedules";
import { Reports3ScheduleEntity } from "@/models/reports/v3/Reports3ScheduleEntity";
import { useOrganisationStore } from "@/stores/organisation";
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { TimeZoneDto } from "@/models/TimeZoneDto";
import ColorHelper from "@/helpers/ColorHelper";
import { useOrganisationUsersStore } from "@/stores/organisationUsers";
import { useNotificationGroupStore } from "@/stores/notificationGroup";
import CustomWindow from '@/CustomWindow';
import chroma from "chroma-js";
import { useReports3Store } from "@/stores/reports3";
import CronEditorView from "@/components/views/CronEditorView.vue";

declare const window: CustomWindow;

@Component({
  components: {
    ProgressSpinner,
    BlockUI,
    InputSwitch,
    Dropdown,
    MultiSelect,
    Chip,
    InputText,
    CronEditorView
  },
  directives: {
  }
})
class ReportsScheduleEditView extends Vue { 
  @Model editRecord!: Reports3ScheduleEntity;
  @Prop({ required: false, default: false }) hideReports!: boolean;

  reports3Store = useReports3Store();
  reports3SchedulesStore = useReports3SchedulesStore();
  organisationStore = useOrganisationStore();
  organisationUsersStore = useOrganisationUsersStore();
  notificationGroupStore = useNotificationGroupStore();

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  get organisationIdStr(): string {
    return this.currentOrganisation ? this.currentOrganisation.Id.toString() : "";
  }

  get timezones(): TimeZoneDto[] {
    return this.$store.state.timezones;
  }

  mounted() {
    if (!this.organisationUsersStore.data[this.organisationIdStr]?.isLoaded) {
      this.organisationUsersStore.load(this.currentOrganisation?.Id || 0);
    }
    if (!this.notificationGroupStore.isLoaded) {
      this.notificationGroupStore.load();
    }
    if (!this.reports3Store.isLoadedLight) {
      this.reports3Store.loadLight();
    }
  }

  get allUserData() : AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  // #region new/edit

  get updateInProgress(): boolean {
    return this.reports3SchedulesStore.updateInProgress;
  }

  get updateError(): boolean {
    return this.reports3SchedulesStore.updateError;
  }

  get emailsAndGroups(): any[] {
    const result = [];
    if (this.notificationGroupStore.entities?.length) {
      result.push({
        label: "Groups",
        items: this.notificationGroupStore.entities.map(x => {
          return { label: x.Name, value: x.Id, backgroundColor: x.Color, color: ColorHelper.getContrastColor(x.Color) };
        })
      });
    }
    const users = this.organisationUsersStore.data[this.organisationIdStr];
    if (users?.entities?.length) {
      let emails = users.entities.map(x => x.UserName);
      const userData = window.angularUserprofile.getAllUserData()
      if (!emails.find(x => x === userData.userName)){
        emails.push(userData.userName);
      }
      emails = emails.sort((a, b) => a.localeCompare(b));
      result.push({
        label: "Emails",
        items: emails.map(x => {
          return { label: x, value: x };
        })
      });
    }
    return result;
  }

  getLabelByValue(value: string): string {
    const group = this.emailsAndGroups.find(x => x.label === value.includes("@") ? "Emails" : "Groups");
    if (group) {
      const result = group.items.find((x: any) => x.value === value);
      return result ? result.label : value;
    }
    return value;
  }

  hasColorByValue(value: string): boolean {
    const group = this.emailsAndGroups.find(x => x.label === value.includes("@") ? "Emails" : "Groups");
    if (group) {
      const result = group.items.find((x: any) => x.value === value);
      return result ? !!result.color : false;
    }
    return false;
  }

  getChipStylesByValue(value: string): string {
    const group = this.emailsAndGroups.find(x => x.label === value.includes("@") ? "Emails" : "Groups");
    if (group) {
      const result = group.items.find((x: any) => x.value === value);
      return result ? this.getChipStyles(result) : "";
    }
    return "";
  }

  getChipStyles(item: any): string {
    let result: string = "";
    if (item.backgroundColor) {
      const colorRGB = chroma.hex(item.backgroundColor).rgb();
      result = `--colored-chip-selected-bg-color: ${colorRGB[0]}, ${colorRGB[1]}, ${colorRGB[2]}; --colored-chip-selected-bg-color-with-opacity: rgba(var(--colored-chip-selected-bg-color), var(--colored-chip-selected-bg-color-opacity));`;
    }
    if (item.color) {
      result = `${result}color: ${item.color};`;
    }
    return result;
  }
  // #endregion new/edit
}

export default ReportsScheduleEditView;
</script>