<template>
  <div>
    <DataTable
      :value="reports3Store.data?.Items" 
      dataKey="Id"
      :totalRecords="reports3Store.data?.Total"
      :paginator="true"
      :rows="reports3Store.take"
      :lazy="true"
      @page="onPage($event)"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport JumpToPageDropdown"
      :rowsPerPageOptions="[10, 20, 50]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      showGridlines 
      responsiveLayout="stack" 
      breakpoint="850px" 
      class="p-datatable-sm default-visual-table responsive-breakpoint data-table">
      <template #header>
        <div class="table-header">
          <div class="md:flex md:align-items-center md:justify-content-between md:gap-3">
            <div class="md:flex-shrink-0">
              <Button 
                :label="`Add ${mode === 'reports'? 'Report' : 'Template'}`"
                icon="pi pi-plus-circle"
                class="my-1"
                @click="createReport"
              />
            </div>
            <div class="mt-3 md:mt-0">
              <IconField iconPosition="left" class="w-full md:w-auto">
                <InputIcon class="pi pi-search" />
                <InputText 
                  v-model="search"
                  @input="debounceSearch()"
                  :placeholder="`Find ${mode === 'reports'? 'Report' : 'Template'}`"
                  class="w-full md:w-auto" 
                />
              </IconField>
            </div>
          </div>
        </div>
      </template>
      <template #empty>
        <div v-if="reports3Store.isLoaded" class="w-full" style="min-height: 50vh;">
          <span class="inline-block py-2">No data found.</span>
        </div>
        <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
          <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
        </div>
      </template>
      <Column field="Name" header="Name" headerStyle="min-width: min-content; width: 55%;" headerClass="no-break-word" bodyClass="no-break-word">
        <template #body="slotProps">
          <span class="block" :class="mode === 'templates' ? 'with-inline-btn left-position' : ''">
            {{ slotProps.data.Name }}
            <span v-if="slotProps.data.OrganisationId === 0" v-tippy="'Public'" class="table-cell-icon">
              <i class="pi pi-globe"></i>
            </span>
          </span>
        </template>
      </Column>
      <Column field="Created" header="Created" headerStyle="min-width: min-content; width: 16%;" headerClass="no-break-word" bodyClass="no-break-word">
        <template #body="slotProps">
          <DateTimezoneView :date="slotProps.data.Created" timezone="local"/>
        </template>
      </Column>
      <Column field="Updated" header="Modified" headerStyle="min-width: min-content; width: 16%;" headerClass="no-break-word" bodyClass="no-break-word">
        <template #body="slotProps">
          <DateTimezoneView :date="slotProps.data.Updated" timezone="local"/>
        </template>
      </Column>
      <Column 
        :exportable="false" 
        headerStyle="width: 1%; min-width: 128px;" 
        bodyStyle="text-align: right; justify-content: flex-end;"
      >
        <template #body="slotProps">
          <div>
            <div class="inline-flex">
              <Button 
                icon="pi pi-clone" 
                class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                @click="cloneItem(slotProps.data)"
                v-tippy="'Copy'"
              />
              <Button 
                icon="pi pi-pencil" 
                class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                @click="openItem(slotProps.data)"
                v-tippy="'Edit'"
              />
              <Button 
                icon="pi pi-trash" 
                class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                @click="openConfirmation(slotProps.data)" 
                :disabled="slotProps.data.OrganisationId === 0 && !authState.permissions?.BitpoolAdmin"
                v-tippy="'Delete'"
              />
            </div>
          </div>
        </template>
      </Column>
    </DataTable>

    <Dialog header="New Report" v-model:visible="displayNewReportWizard" :modal="true" :style="{width: '97rem'}" class="report-manager-dialog bp-stepper-dialog">
      <div class="dialog-content">
        <Stepper :linear="true" v-model:activeStep="activeStep" class="bp-stepper">
          <StepperPanel>
            <template #header="{ index, clickCallback }">
              <button class="bp-stepper-step-header" @click="clickCallback">
                <span :class="['bp-stepper-step-title', { 'bp-stepper-step-active': index == activeStep, 'bp-stepper-step-passed': index < activeStep }]">
                  Report type
                </span>
                <i :class="['bp-stepper-step-indicator', { 'bp-stepper-step-active': index == activeStep, 'bp-stepper-step-passed': index < activeStep }]"></i>
              </button>
            </template>

            <template #content="{ nextCallback }">
              <div class="bp-stepper-body">
                <div class="bp-stepper-body-inner">
                  <div class="flex flex-column">
                    <h3>What type of report are you creating?</h3>
                    <div v-for="rt in reportTypes" :key="rt.value" class="radiobutton-field">
                      <RadioButton v-model="reportType" :inputId="`report-type-${rt.value}`" name="dynamic" :value="rt.value" />
                      <label :for="`report-type-${rt.value}`">{{ rt.label }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bp-stepper-footer">
                <Button label="Skip" outlined class="text-lg" icon="pi pi-times" @click="openItem(null)" />
                <Button label="Next" icon="pi pi-arrow-right" iconPos="right" class="text-lg" @click="nextCallback($event); loadStreams();" />
              </div>
            </template>
          </StepperPanel>

          <StepperPanel v-if="reportType === 'template'">
            <template #header="{ index, clickCallback }">
              <button class="bp-stepper-step-header" @click="clickCallback">
                <span :class="['bp-stepper-step-title', { 'bp-stepper-step-active': index == activeStep, 'bp-stepper-step-passed': index < activeStep }]">
                  Template
                </span>
                <i :class="['bp-stepper-step-indicator', { 'bp-stepper-step-active': index == activeStep, 'bp-stepper-step-passed': index < activeStep }]"></i>
              </button>
            </template>
            
            <template #content="{ prevCallback, nextCallback }">
              <div class="bp-stepper-body">
                <div class="bp-stepper-body-inner">
                  <div class="flex flex-column">
                    <h3>Select a report template to use</h3>
                    <Dropdown 
                      :options="reports3TemplatesStore.dataLight ?? []" 
                      :loading="!reports3TemplatesStore.isLoadedLight"
                      v-model="selectedTemplateLight"
                      optionLabel="Name"
                      placeholder="Select a template"
                      class="w-full size-large"
                    />
                  </div>
                </div>
              </div>
              <div class="bp-stepper-footer">
                <Button label="Back" outlined class="text-lg" icon="pi pi-arrow-left" @click="prevCallback" />
                <Button label="Next" icon="pi pi-arrow-right" iconPos="right" class="text-lg" :disabled="!selectedTemplateLight" @click="nextCallback($event); loadTemplate();" />
              </div>
            </template>
          </StepperPanel>

          <StepperPanel>
            <template #header="{ index, clickCallback }">
              <button class="bp-stepper-step-header" @click="clickCallback">
                <span :class="['bp-stepper-step-title', { 'bp-stepper-step-active': index == activeStep, 'bp-stepper-step-passed': index < activeStep }]">
                  Report name
                </span>
                <i :class="['bp-stepper-step-indicator', { 'bp-stepper-step-active': index == activeStep, 'bp-stepper-step-passed': index < activeStep }]"></i>
              </button>
            </template>

            <template #content="{ prevCallback, nextCallback }">
              <div class="bp-stepper-body">
                <div class="bp-stepper-body-inner">
                  <div class="flex flex-column">
                    <h3>Name your report</h3>
                    <InputText v-model="reportName" :placeholder="'Enter report name'" class="size-large" />
                  </div>
                </div>
              </div>
              <div class="bp-stepper-footer">
                <Button label="Back" outlined class="text-lg" icon="pi pi-arrow-left" @click="prevCallback" />
                <Button label="Next" icon="pi pi-arrow-right" iconPos="right" class="text-lg" :disabled="!reportName" @click="nextCallback($event); loadSchedules();" />
              </div>
            </template>
          </StepperPanel>

          <StepperPanel v-if="reportType === 'template'">
            <template #header="{ index, clickCallback }">
              <button class="bp-stepper-step-header" @click="clickCallback">
                <span :class="['bp-stepper-step-title', { 'bp-stepper-step-active': index == activeStep, 'bp-stepper-step-passed': index < activeStep }]">
                  Streams
                </span>
                <i :class="['bp-stepper-step-indicator', { 'bp-stepper-step-active': index == activeStep, 'bp-stepper-step-passed': index < activeStep }]"></i>
              </button>
            </template>

            <template #content="{ index, prevCallback, nextCallback }">
              <div class="bp-stepper-body">
                <div class="bp-stepper-body-inner">
                  <div class="flex flex-column" v-if="selectedTemplate && index === activeStep">
                    <h3>Add data streams to your report</h3>
                    <div v-for="datasource in selectedTemplate.Datasources" :key="datasource.Uid">
                      <div class="bp-stepper-body-subtitle">{{ datasource.Name }}</div>
                      <ReportsDatasourceStreamsView :datasource="datasource" :disableModifications="true" :collapsedOnInit="false"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bp-stepper-footer">
                <Button label="Back" outlined class="text-lg" icon="pi pi-arrow-left" @click="prevCallback" />
                <Button label="Next" icon="pi pi-arrow-right" iconPos="right" class="text-lg" @click="nextCallback" />
              </div>
            </template>
          </StepperPanel>

          <StepperPanel>
            <template #header="{ index, clickCallback }">
              <button class="bp-stepper-step-header" @click="clickCallback">
                <span :class="['bp-stepper-step-title', { 'bp-stepper-step-active': index == activeStep, 'bp-stepper-step-passed': index < activeStep }]">
                  Schedule
                </span>
                <i :class="['bp-stepper-step-indicator', { 'bp-stepper-step-active': index == activeStep, 'bp-stepper-step-passed': index < activeStep }]"></i>
              </button>
            </template>

            <template #content="{ prevCallback }">
              <div class="bp-stepper-body">
                <div class="bp-stepper-body-inner">
                  <div class="flex flex-column">
                    <h3>Create or choose a report schedule</h3>
                    <MultiSelect
                      inputId="editRecordSchedules"
                      v-model="selectedSchedules"
                      :options="reports3SchedulesStore.dataLight"
                      :loading="!reports3SchedulesStore.isLoadedLight"
                      placeholder="Select Schedules"
                      display="chip"
                      :filter="true"
                      optionValue="Id"
                      optionLabel="Name"
                      class="p-multiselect-multiline inputfield w-full size-large"
                      :disabled="reports3Store.updateInProgress"
                    />
                    <span class="bp-stepper-body-divider">OR</span>
                    <Button 
                      label="Create a new schedule" 
                      outlined 
                      class="bp-stepper-body-inner-button" 
                      @click="openNewScheduleDialog" 
                      :disabled="reports3Store.updateInProgress"
                    />
                    
                    <Dialog header="Schedule Configuration" v-model:visible="displayNewScheduleDialog" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" class="data-config-dialog">
                      <ReportsScheduleEditView class="dialog-content" v-model="newSchedule" :hideReports="true"/>
                      <template #footer>
                        <div class="flex flex-wrap sm:flex-nowrap justify-content-end" style="row-gap: .5rem;">
                          <span class="block">
                            <Button label="Cancel" icon="pi pi-times" @click="displayNewScheduleDialog = false" class="p-button-text p-button-secondary"/>
                          </span>
                          <span class="block ml-2">
                            <Button v-if="newSchedule" label="Create" :icon="updateInProgressSchedule ? 'pi pi-spin pi-spinner' : 'pi pi-check'" @click="saveNewSchedule" :disabled='!newSchedule.Name || !newSchedule.CronExpression || !newSchedule.Timezone || updateInProgressSchedule' />
                          </span>
                        </div>
                      </template>
                    </Dialog>
                  </div>
                </div>
              </div>
              <div class="bp-stepper-footer">
                <Button label="Back" outlined class="text-lg" icon="pi pi-arrow-left" @click="prevCallback" :disabled="reports3Store.updateInProgress" />
                <Button label="Create" :icon="reports3Store.updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-save'" iconPos="right" class="text-lg" @click="createReportFromWizard" :disabled="reports3Store.updateInProgress" />
              </div>
            </template>
          </StepperPanel>
        </Stepper>
      </div>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Message from 'primevue/message';
import ProgressSpinner from 'primevue/progressspinner';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import Dialog from "primevue/dialog";
import Stepper from "primevue/stepper";
import StepperPanel from "primevue/stepperpanel";
import RadioButton from "primevue/radiobutton";
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import AuthState from "@/store/states/AuthState";
import ConfirmationService from "@/services/ConfirmationService";
import { useReports3Store } from "@/stores/reports3";
import { Reports3Entity } from "@/models/reports/v3/Reports3Entity";
import NavigationHelper from "@/helpers/NavigationHelper";
import { AllUserData } from "@/models/user/AllUserData";
import moment from "moment";
import { debounce } from "throttle-debounce";
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";
import { Reports3EntityLight } from "@/models/reports/v3/Reports3EntityLight";
import { Reports3TemplateEntity } from "@/models/reports/v3/Reports3TemplateEntity";
import ReportsDatasourceStreamsView from "@/components/views/reports/ReportsDatasourceStreamsView.vue";
import { useNavTreeStore } from "@/stores/navTree";
import { useReports3SchedulesStore } from "@/stores/reports3Schedules";
import { Reports3ScheduleEntity } from "@/models/reports/v3/Reports3ScheduleEntity";
import { useOrganisationStore } from "@/stores/organisation";
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import ReportsScheduleEditView from "@/components/views/reports/ReportsScheduleEditView.vue";
import { AggregationPeriod } from "@/models/enums/AggregationPeriod";

@Component({
  components: {
    Button,
    DataTable,
    Column,
    Message,
    ProgressSpinner,
    IconField,
    InputIcon,
    InputText,
    Dialog,
    Stepper,
    StepperPanel,
    RadioButton,
    Dropdown,
    MultiSelect,
    DateTimezoneView,
    ReportsDatasourceStreamsView,
    ReportsScheduleEditView
  },
  directives: {
  }
})
class ReportsReportsView extends Vue {
  @Prop({ required: false, default: "reports" }) mode!: string;
  
  get reports3Store() {
    return useReports3Store(this.mode);
  }

  reports3TemplatesStore = useReports3Store("templates");
  reports3SchedulesStore = useReports3SchedulesStore();
  navTreeStore = useNavTreeStore();
  organisationStore = useOrganisationStore();

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  get isAvailable(): boolean | undefined {
    const result = (this.authState.permissions?.FullAccess || this.authState.permissions?.Reports);
    return result;
  }

  mounted() {
    this.search = this.reports3Store.search;
    this.searchFinal = this.reports3Store.search;
    this.loadData();
  }

  search = '';
  searchFinal = '';

  debounceSearch = debounce(500, this.updateFinalSearch);

  updateFinalSearch(): void {
    this.searchFinal = this.search;
  }

  @Watch('searchFinal', { immediate: false, deep: false })
  onInputChanged(): void {
    this.loadData();
  }

  loadData(): void {
    if (this.isAvailable) {
      this.reports3Store.load(this.reports3Store.skip, this.reports3Store.take, this.searchFinal);
    }
  }

  onPage(event: any): void {
    // event.page: New page number
    // event.first: Index of first record
    // event.rows: Number of rows to display in new page
    // event.pageCount: Total number of pages
    this.reports3Store.skip = event.page * event.rows;
    this.reports3Store.take = event.rows;
    this.loadData();
  }

  get allUserData() : AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  async cloneItem(record: Reports3Entity): Promise<void> {
    const clone: Reports3Entity = JSON.parse(JSON.stringify(record));
    clone.Id = "";
    clone.Name += " - Copy"
    const nowUtc = moment.utc().toDate();
    clone.Created = nowUtc;
    clone.Updated = nowUtc;
    clone.CreatedBy = this.allUserData.userName;
    clone.UpdatedBy = this.allUserData.userName;
    const result = await this.reports3Store.createUpdate(clone);
    if (result) {
      this.openItem(result);
    }
  }

  createReport(): void {
    if (this.mode === 'templates') {
      this.openItem(null);
    } else {
      this.openNewReportWizard();
    }
  }

  openItem(record: Reports3Entity | null): void {
    NavigationHelper.goTo(`${this.mode === 'reports' ? '/report-manager/report' : '/report-manager/template'}/${record ? record.Id : 'new'}`);
  }

  // #region delete
  selectedRecord: Reports3Entity | null = null;

  openConfirmation(record: Reports3Entity | null): void {
    this.selectedRecord = record;
    const message = `Are you sure you want to delete ${this.selectedRecord?.Name}?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: async () => {
        // callback to execute when user confirms the action
        if (this.selectedRecord) {
          await this.reports3Store.delete(this.selectedRecord);
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }
  // #endregion delete

  // #region wizard
  displayNewReportWizard = false;
  activeStep = 0;
  reportType = "";
  reportTypes = [{label: "Custom", value: "custom"}, {label: "From template", value: "template"}];
  selectedTemplateLight: Reports3EntityLight | null = null;
  selectedTemplate: Reports3TemplateEntity | null = null;
  reportName = "";
  selectedSchedules: string[] = [];

  openNewReportWizard(): void {
    this.activeStep = 0;
    this.reports3TemplatesStore.loadLight();
    this.reportType = "custom";
    this.selectedTemplateLight = null;
    this.selectedTemplate = null;
    this.reportName = "";
    this.selectedSchedules = [];
    this.displayNewReportWizard = true;
  }

  async loadTemplate(): Promise<void> {
    if (this.reportType === "template" && this.selectedTemplateLight) {
      this.selectedTemplate = null;
      await this.reports3TemplatesStore.loadOne(this.selectedTemplateLight.Id);
      const st = JSON.parse(JSON.stringify(this.reports3TemplatesStore.dataOne));
      if (st?.Datasources.length) {
        for (let ds of st.Datasources) {
          for (let so of ds.Configuration.streamOptions) {
            so.StreamKey = "";
            so.Name = "";
          }
        }
      }
      this.selectedTemplate = st;
    }
  }

  loadStreams(): void {
    if (this.reportType === "template") {
      // load streams for data sources
      if (!this.navTreeStore.isLoaded) {
        this.navTreeStore.load();
      }
      if (!this.navTreeStore.unstructuredIsLoaded) {
        this.navTreeStore.loadUnstructured();
      }
    }
  }

  loadSchedules(): void {
    this.reports3SchedulesStore.loadLight();
  }
  
  displayNewScheduleDialog = false;
  newSchedule: Reports3ScheduleEntity | null = null;

  openNewScheduleDialog(): void {
    const nowUtc = moment.utc().toDate();
    this.newSchedule = {
      Id: "",
      Name: "",
      OrganisationId: this.currentOrganisation?.Id ?? -1,
      Reports: [],
      Recipients: [],
      CronExpression: "0 0 1 ? * 2",
      Timezone: "Brisbane",
      Enabled: true,
      Created: nowUtc,
      Updated: nowUtc,
      CreatedBy: "", // api will fill it
      UpdatedBy: "" // api will fill it
    };
    this.displayNewScheduleDialog = true;
  }
  
  get updateInProgressSchedule(): boolean {
    return this.reports3SchedulesStore.updateInProgress;
  }

  get updateErrorSchedule(): boolean {
    return this.reports3SchedulesStore.updateError;
  }

  async saveNewSchedule(): Promise<void> {
    if (this.newSchedule) {
      const newSchedule = await this.reports3SchedulesStore.createUpdate(this.newSchedule);
      if (!this.updateErrorSchedule && newSchedule) {
        if (this.reports3SchedulesStore.dataLight) {
          this.reports3SchedulesStore.dataLight.push({ Id: newSchedule.Id, Name: newSchedule.Name });
          this.selectedSchedules.push(newSchedule.Id);
        }
        this.displayNewScheduleDialog = false;
      }
    }
  }

  async createReportFromWizard(): Promise<void> {
    const nowUtc = moment.utc().toDate();
    let newReport: Reports3Entity;
    if (this.reportType === "template") {
      // create newReport from template
      newReport = JSON.parse(JSON.stringify(this.selectedTemplate));
      newReport.Id = "";
      newReport.Name = this.reportName;
      newReport.OrganisationId = this.currentOrganisation?.Id ?? -1;
      newReport.Created = nowUtc;
      newReport.Updated = nowUtc;
      newReport.CreatedBy = ""; // api will fill it
      newReport.UpdatedBy = ""; // api will fill it
    } else {
      // create newReport
      newReport = {
        Id: "",
        Name: this.reportName,
        Header: null,
        Items: [],
        Footer: null,
        DateRange: {
          active: true,
          rangePreset: 2,
          rangePresetHolder: 2,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          aggPeriod: AggregationPeriod.Hourly,
          autoAggPeriod: true
        },
        Datasources: [],
        OrganisationId: this.currentOrganisation?.Id ?? -1,
        MarginTop: 1,
        MarginBottom: 1,
        MarginLeft: 1,
        MarginRight: 1,
        Created: nowUtc,
        Updated: nowUtc,
        CreatedBy: "", // api will fill it
        UpdatedBy: "" // api will fill it
      }
    }
    const result = await this.reports3Store.createUpdate(newReport, this.selectedSchedules);
    if (result) {
      this.openItem(result);
      this.displayNewReportWizard = false;
    }
  }
  // #endregion wizard
}

export default ReportsReportsView;
</script>