<template>
  <div>
    <DataTable
      :value="reports3SchedulesStore.data?.Items" 
      dataKey="Id"
      :totalRecords="reports3SchedulesStore.data?.Total"
      :paginator="true"
      :rows="reports3SchedulesStore.take"
      :lazy="true"
      @page="onPage($event)"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport JumpToPageDropdown"
      :rowsPerPageOptions="[10, 20, 50]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      showGridlines 
      responsiveLayout="stack" 
      breakpoint="850px" 
      class="p-datatable-sm default-visual-table responsive-breakpoint data-table">
      <template #header>
        <div class="table-header">
          <div class="md:flex md:align-items-center md:justify-content-between md:gap-3">
            <div class="md:flex-shrink-0">
              <Button 
                label="Add Schedule" 
                icon="pi pi-plus-circle" 
                class="my-1" 
                @click="openCreateUpdateDialog(null)" 
              />
            </div>
            <div class="mt-3 md:mt-0">
              <IconField iconPosition="left" class="w-full md:w-auto">
                <InputIcon class="pi pi-search" />
                <InputText 
                  v-model="search"
                  @input="debounceSearch()"
                  placeholder="Find Schedule" 
                  class="w-full md:w-auto" 
                />
              </IconField>
            </div>
          </div>
        </div>
      </template>
      <template #empty>
        <div v-if="reports3SchedulesStore.isLoaded" class="w-full" style="min-height: 50vh;">
          <span class="inline-block py-2">No data found.</span>
        </div>
        <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
          <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
        </div>
      </template>
      <Column field="Enabled" header="Enabled" headerStyle="width: 1%; min-width: 65px;">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.Enabled ? 'Yes' : 'No' }}
          </div>
        </template>
      </Column>
      <Column field="Name" header="Name" headerStyle="min-width: min-content; width: 52%;" headerClass="no-break-word" bodyClass="no-break-word"></Column>
      <Column field="Created" header="Created" headerStyle="min-width: min-content; width: 16%;" headerClass="no-break-word" bodyClass="no-break-word">
        <template #body="slotProps">
          <DateTimezoneView :date="slotProps.data.Created" timezone="local"/>
        </template>
      </Column>
      <Column field="Updated" header="Modified" headerStyle="min-width: min-content; width: 16%;" headerClass="no-break-word" bodyClass="no-break-word">
        <template #body="slotProps">
          <DateTimezoneView :date="slotProps.data.Updated" timezone="local"/>
        </template>
      </Column>
      <Column 
        :exportable="false" 
        headerStyle="width: 1%; min-width: 169px;" 
        bodyStyle="text-align: right; justify-content: flex-end;"
      >
        <template #body="slotProps">
          <div>
            <div class="inline-flex">
              <Button 
                icon="pi pi-book" 
                class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                @click="openLogsDialog(slotProps.data)"
                v-tippy="'Logs'"
              />
              <Button 
                icon="pi pi-clone" 
                class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                @click="openCloneDialog(slotProps.data)"
                v-tippy="'Copy'"
              />
              <Button 
                icon="pi pi-pencil" 
                class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                @click="openCreateUpdateDialog(slotProps.data)"
                v-tippy="'Edit'"
              />
              <Button 
                icon="pi pi-trash" 
                class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                @click="openConfirmation(slotProps.data)" 
                v-tippy="'Delete'"
              />
            </div>
          </div>
        </template>
      </Column>
    </DataTable>

    <Dialog header="Schedule Configuration" v-model:visible="displayCreateUpdateDialog" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" class="data-config-dialog">
      <ReportsScheduleEditView class="dialog-content" v-model="editRecord"/>
      <template #footer>
        <div class="flex flex-wrap sm:flex-nowrap justify-content-end" style="row-gap: .5rem;">
          <span class="block">
            <Button label="Cancel" icon="pi pi-times" @click="closeCreateUpdateDialog" class="p-button-text p-button-secondary"/>
          </span>
          <span class="block ml-2">
            <Button v-if="editRecord" :label="editRecord.Id ? 'Update' : 'Create'" :icon="updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" @click="saveRecord" :disabled='!editRecord.Name || !editRecord.CronExpression || !editRecord.Timezone || updateInProgress' />
          </span>
        </div>
      </template>
    </Dialog>

    <Dialog header="Logs" v-model:visible="displayLogsDialog" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" class="connection-history-config-dialog">
      <div class="dialog-content" v-if="selectedRecord">
        <ConnectionHistoryView v-model="selectedRecord.Id" :type="logsType"/>
      </div>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-facing-decorator";
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Message from 'primevue/message';
import ProgressSpinner from 'primevue/progressspinner';
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import AuthState from "@/store/states/AuthState";
import ConfirmationService from "@/services/ConfirmationService";
import { AllUserData } from "@/models/user/AllUserData";
import moment from "moment";
import { useReports3SchedulesStore } from "@/stores/reports3Schedules";
import { Reports3ScheduleEntity } from "@/models/reports/v3/Reports3ScheduleEntity";
import { useOrganisationStore } from "@/stores/organisation";
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import CustomWindow from '@/CustomWindow';
import { ConnectionHistoryType } from "@/models/connection/ConnectionHistoryType";
import ConnectionHistoryView from "@/components/views/connections/ConnectionHistoryView.vue";
import { debounce } from "throttle-debounce";
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";
import ReportsScheduleEditView from "@/components/views/reports/ReportsScheduleEditView.vue";

declare const window: CustomWindow;

@Component({
  components: {
    Button,
    DataTable,
    Column,
    Message,
    ProgressSpinner,
    Dialog,
    Dropdown,
    ConnectionHistoryView,
    IconField,
    InputIcon,
    InputText,
    DateTimezoneView,
    ReportsScheduleEditView
  },
  directives: {
  }
})
class ReportsSchedulesView extends Vue {
  reports3SchedulesStore = useReports3SchedulesStore();
  organisationStore = useOrganisationStore();

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  get isAvailable(): boolean | undefined {
    const result = (this.authState.permissions?.FullAccess || this.authState.permissions?.Reports);
    return result;
  }

  mounted() {
    this.search = this.reports3SchedulesStore.search;
    this.searchFinal = this.reports3SchedulesStore.search;
    this.loadData();
  }

  search = '';
  searchFinal = '';

  debounceSearch = debounce(500, this.updateFinalSearch);

  updateFinalSearch(): void {
    this.searchFinal = this.search;
  }

  @Watch('searchFinal', { immediate: false, deep: false })
  onInputChanged(): void {
    this.loadData();
  }

  loadData(): void {
    if (this.isAvailable) {
      this.reports3SchedulesStore.load(this.reports3SchedulesStore.skip, this.reports3SchedulesStore.take, this.searchFinal);
    }
  }

  onPage(event: any): void {
    // event.page: New page number
    // event.first: Index of first record
    // event.rows: Number of rows to display in new page
    // event.pageCount: Total number of pages
    this.reports3SchedulesStore.skip = event.page * event.rows;
    this.reports3SchedulesStore.take = event.rows;
    this.loadData();
  }

  get allUserData() : AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  // #region new/edit
  displayCreateUpdateDialog = false;
  editRecord: Reports3ScheduleEntity | null = null;

  openCloneDialog(record: Reports3ScheduleEntity): void {
    const newRecord: Reports3ScheduleEntity = JSON.parse(JSON.stringify(record));
    newRecord.Id = "";
    newRecord.Name += " - Copy";
    const nowUtc = moment.utc().toDate();
    newRecord.Created = nowUtc;
    newRecord.Updated = nowUtc;
    newRecord.CreatedBy = this.allUserData.userName;
    newRecord.UpdatedBy = this.allUserData.userName;
    this.openCreateUpdateDialog(newRecord);
  }

  openCreateUpdateDialog(record: Reports3ScheduleEntity | null): void {
    const nowUtc = moment.utc().toDate();
    this.editRecord = record ? Object.assign({}, record) : {
      Id: "",
      Name: "",
      OrganisationId: this.currentOrganisation?.Id ?? -1,
      Reports: [],
      Recipients: [],
      CronExpression: "0 0 1 ? * 2",
      Timezone: "Brisbane",
      Enabled: true,
      Created: nowUtc,
      Updated: nowUtc,
      CreatedBy: "", // api will fill it
      UpdatedBy: "" // api will fill it
    };
    this.displayCreateUpdateDialog = true;
  }

  closeCreateUpdateDialog(): void {
    this.displayCreateUpdateDialog = false;
  }

  get updateInProgress(): boolean {
    return this.reports3SchedulesStore.updateInProgress;
  }

  get updateError(): boolean {
    return this.reports3SchedulesStore.updateError;
  }

  async saveRecord(): Promise<void> {
    if (this.editRecord) {
      await this.reports3SchedulesStore.createUpdate(this.editRecord);
      if (!this.updateError) {
        this.displayCreateUpdateDialog = false;
      }
    }
  }
  // #endregion new/edit

  // #region delete
  selectedRecord: Reports3ScheduleEntity | null = null;

  openConfirmation(record: Reports3ScheduleEntity | null): void {
    this.selectedRecord = record;
    const message = `Are you sure you want to delete ${this.selectedRecord?.Name}?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: async () => {
        // callback to execute when user confirms the action
        if (this.selectedRecord) {
          await this.reports3SchedulesStore.delete(this.selectedRecord);
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }
  // #endregion delete
  
  // #region logs
  displayLogsDialog = false;
  logsType = ConnectionHistoryType.ReportSchedule;

  openLogsDialog(record: Reports3ScheduleEntity | null): void {
    this.selectedRecord = record;
    this.displayLogsDialog = true;
  }
  // #endregion logs
}

export default ReportsSchedulesView;
</script>