<template>
  <div v-if="!authState.jailMode" class="dashboard-menu__controls">
    <Button type="button" @click="showMainMenu" v-tippy="'Go to Main Menu'" class="p-button-text p-button-icon-only p-button-rounded p-button-lg go-to-main-menu">
        <span class="p-button-icon-group p-button-icon-left flex justify-content-center align-items-center w-full h-full">
          <i class="pi pi-angle-left p-button-icon -mr-3"></i>
          <i class="pi pi-angle-left p-button-icon"></i>
        </span>
    </Button>

    <span class="dashboard-menu__controls-text">Dashboards</span>

    <Button v-if="canCreateDashboard" @click="openNewDashboardDialog(null)" v-tippy="'Create Dashboard'" class="p-button-text p-button-icon-only p-button-rounded p-button-lg add-dashboard">
      <PlusCircleSvg/>
    </Button>
    <DashboardNewDialogView ref="newDashboardDialog"/>
    <DashboardRenameDialogView ref="renameDashboardDialog"/>

    <div v-if="canCreateDashboard" v-tippy="'Dashboard Tree Drag & Drop'" class="drag-drop-toggle">
      <label for="dragDropSwitch">
          <input type="checkbox" id="dragDropSwitch" v-model="editModeTree" />
          <span class="p-button p-button-text p-button-icon-only p-button-rounded p-button-lg drag-drop-disabled" :class="editModeTree  ? 'drag-drop-enabled' : 'drag-drop-disabled'">
            <DragDropEnabledSvg v-if="editModeTree" />
            <DragDropDisabledSvg v-else/>
          </span>
      </label>
    </div>
  </div>
  <div v-if="!authState.jailMode" class="dashboard-menu__top-level">
    <div :class="{'is-active': dashboardType === dashboardTypes.Organisation}" @click="setPane(dashboardTypes.Organisation)" class="dashboard-menu__type dashboard-menu__type_organisation">
      <span>Organisation</span>
    </div>
    <div :class="{'is-active': dashboardType === dashboardTypes.Personal}"  @click="setPane(dashboardTypes.Personal)" class="dashboard-menu__type dashboard-menu__type_personal">
      <span>Personal</span>
    </div>
  </div>

  <div class="dashboard-menu__search">
    <IconField iconPosition="left" class="search-input-box">
      <InputIcon class="pi pi-search text-lg"></InputIcon>
      <InputText
        class="inputfield text-base text-lg"
        placeholder="Filter Dashboard"
        type="text"
        v-model="search"
        @input="debounceSearch()"
      />
    </IconField>
  </div>
  
  <div 
    v-show="dashboardState.isLoaded && safariFix" 
    ref="treeElement"
    class="dashboard-tree" 
    @dragenter="dragEnter"
    @touchmove="dragEnter"
  >
    <draggable
      tag="ul"
      handle=".handle"
      v-if="rootNode"
      v-model="children"
      group="nav-tree"
      :animation="150"
      :disabled="!editModeTree"
      class="list-group dashboard-tree__list"
      item-key="_id"
      @start="onDragStart"
      @end="onDragEnd"
      :class="{'draggable-available': editModeTree}"
      ghostClass="dragging-tree"
    >
      <template #item="{ element }">
        <DashboardTreeItemView
          :dashboardId="element._id"
          :afterNavigateTo="afterNavigateTo"
          :openDashboardMenu="openDashboardMenu"
        />
      </template>
    </draggable>
  </div>
  <div class="w-full flex justify-content-center align-items-center flex-auto pt-3 pb-5" v-if="!(dashboardState.isLoaded && safariFix)">
    <ProgressSpinner class="spinner-white" style="width: 40px; height: 40px" strokeWidth="6" animationDuration="1s" />
  </div>
  <ContextMenu ref="menu" :model="menuItems" class="tree-context-menu" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import { Space } from "@/models/dashboard/Space";
import DashboardState from "@/store/states/DashboardState";
import DashboardTreeItemView from "./DashboardTreeItemView.vue";
import PlusCircleSvg from "@/components/svg/PlusCircleSvg.vue";
import DragDropDisabledSvg from "@/components/svg/DragDropDisabledSvg.vue";
import DragDropEnabledSvg from "@/components/svg/DragDropEnabledSvg.vue";
import SpaceHelper from "@/helpers/SpaceHelper";
import { Ref, Watch } from "vue-facing-decorator";
import NavigationHelper from "@/helpers/NavigationHelper";
import InputText from 'primevue/inputtext';
import InputSwitch from 'primevue/inputswitch';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import ProgressSpinner from 'primevue/progressspinner';
import ContextMenu from "primevue/contextmenu";
import { MenuItem } from "primevue/menuitem";
import { debounce } from 'throttle-debounce';
import draggable from "vuedraggable";
import AuthState from "@/store/states/AuthState";
import { nextTick, reactive } from "vue";
import RootState from "@/store/states/RootState";
import { CopySpaceModel } from "@/models/dashboard/CopySpaceModel";
import ConfirmationService from "@/services/ConfirmationService";
import ToastService from "@/services/ToastService";
import { DashboardType } from "@/models/dashboard/DashboardType";
import { useOrganisationStore } from "@/stores/organisation";
import { useSystemStore } from "@/stores/system";
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import DashboardNewDialogView from "./DashboardNewDialogView.vue";
import DashboardRenameDialogView from "./DashboardRenameDialogView.vue";


@Component({
  components: {
    InputText,
    InputSwitch,
    Button,
    Dialog,
    BlockUI,
    ProgressSpinner,
    ContextMenu,
    DashboardTreeItemView,
    PlusCircleSvg,
    DragDropDisabledSvg,
    DragDropEnabledSvg,
    draggable,
    IconField,
    InputIcon,
    DashboardNewDialogView,
    DashboardRenameDialogView
  },
  directives: {  
  }
})
class DashboardTreeRootView extends Vue {
  @Prop({ required: true }) showMainMenu!: () => void;
  @Prop({ required: true }) afterNavigateTo!: () => void;

  dashboardTypes = DashboardType;

  get rootState(): RootState {
    return this.$store.state;
  }

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  organisationStore = useOrganisationStore();
  systemStore = useSystemStore();

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get editModeTree(): boolean {
    return this.dashboardState.editModeTree;
  }

  set editModeTree(value: boolean) {
    this.$store.commit("dashboard/setEditModeTree", value);
  }

  get dashboardType(): DashboardType {
    return this.dashboardState.dashboardType;
  }

  get dashboards(): Space[] | null | undefined {
    return this.$store.getters["dashboard/getDashboards"];
  }

  get rootNode(): Space | null | undefined {
    return SpaceHelper.getRootSpace(this.dashboards);
  }

  get children(): Space[] | null | undefined {
    return SpaceHelper.getChildren(this.dashboards, this.rootNode);
  }

  set children(spaces: Space[] | null | undefined) {
    const parentSpace = this.rootNode;
    const oldSpaces = this.children;
    const saveSpaces = SpaceHelper.moveSpace(parentSpace, oldSpaces, spaces, this.dashboards ? this.dashboards : []);
    // save saveSpaces
    this.saveDashboards(saveSpaces);
  }

  async saveDashboards(dashboards: Space[]): Promise<void> {
    if (dashboards && dashboards.length) {
      await this.$store.dispatch(
        "dashboard/saveDashboards", 
        { 
          dashboards: dashboards,
          organisationId: this.dashboardState.dashboardType === DashboardType.Organisation && this.organisationStore.currentOrganisation ? this.organisationStore.currentOrganisation.Id : undefined
        }
      );
    }
  }

  // oh, safari is new internet explorer => ios safary can't render divs before tree if tree contain scroll
  safariFix = false;

  @Ref() readonly treeElement!: HTMLElement;

  mounted(): void {
    this.setupContextMenu();
    window.setTimeout(() => {
      this.safariFix = true;
    }, 100);
  }

  async setupContextMenu(): Promise<void> {
    if (!this.authState.jailMode) {
      await nextTick();
      if (this.treeElement) {
        this.treeElement.addEventListener("contextmenu", (event) => {
          this.openDashboardMenu(event, null);
        });
        // contextmenu event is not compatible with iOS - https://developer.mozilla.org/en-US/docs/Web/API/Element/contextmenu_event 
        if (this.systemStore.isIOs) {
          // Timer for long touch detection
          let timerLongTouch: number | undefined = undefined;
          // Long touch flag for preventing "normal touch event" trigger when long touch ends
          let longTouch = false;

          this.treeElement.addEventListener("touchstart", (event) => {
            if (event.target instanceof Element) {
              const element = event.target as Element;
              // make sure it's our top elemnt
              if (element.classList.contains("dashboard-tree")) {              
                // Timer for long touch detection
                timerLongTouch = window.setTimeout(function () {
                  // Flag for preventing "normal touch event" trigger when touch ends.
                  longTouch = true;
                }, 700);
              }
            }
          });
          this.treeElement.addEventListener("touchmove", (event) => {
            // If timerLongTouch is still running, then this is not a long touch
            // (there is a move) so stop the timer
            clearTimeout(timerLongTouch);

            if (longTouch) {
              longTouch = false;
            }
          });
          this.treeElement.addEventListener("touchend", (event) => {
            // If timerLongTouch is still running, then this is not a long touch
            // so stop the timer
            clearTimeout(timerLongTouch);

            if (longTouch) {
              longTouch = false;
              this.openDashboardMenu(event, null);
            }
          });
        }
      }
    }
  }

  onDragStart(event: any): void {
    if (this.children) {
      const space = this.children[event.oldIndex];
      this.$store.commit("dashboard/startDrag", space);
    }
  }

  onDragEnd(): void {
    this.$store.commit("dashboard/stopDrag");
  }

  dragEnter(): void {
    if (this.rootNode && this.dashboardState.spaceDragId) {
      this.$store.commit("dashboard/startDragTimer", this.rootNode);
    }
  }

  setPane(pane: DashboardType): void {
    if (pane !== this.dashboardType) {
      this.editModeTree = false;
      NavigationHelper.goToDashboard(pane, null);
    }
  }

  search = '';
  searchFinal = '';
  debounceSearch = debounce(1000, this.updateFinalSearch);

  updateFinalSearch(): void {
    this.searchFinal = this.search;
  }

  @Watch('searchFinal', { immediate: false, deep: false })
  onSearchFinalChanged(): void {
    this.$store.commit("dashboard/searchDashboard", this.searchFinal);
  }

  // #region create/rename dashboard
  openNewDashboardDialog(parentDashboard: Space | null | undefined): void {
    if (this.$refs.newDashboardDialog) {
      if (parentDashboard) {
        (this.$refs.newDashboardDialog as DashboardNewDialogView).openDialog(parentDashboard);
      } else {
        if (this.dashboardState.currentDashboard) {
          // parent - dashboardState.currentDashboard
          (this.$refs.newDashboardDialog as DashboardNewDialogView).openDialog(this.dashboardState.currentDashboard);
        } else if (this.rootNode) {
          // parent - rootNode
          (this.$refs.newDashboardDialog as DashboardNewDialogView).openDialog(this.rootNode);
        }
      }
    }
  }

  openRenameDashboardDialog(dashboard: Space): void {
    if (this.$refs.renameDashboardDialog) {
      (this.$refs.renameDashboardDialog as DashboardRenameDialogView).openDialog(dashboard);
    }
  }
  // #endregion create/rename dashboard

  // #region context menu
  @Ref() readonly menu!: ContextMenu;
  contextMenuSpace: Space | null | undefined = null;
  
  openDashboardMenu(event: Event, dashboard: Space | null | undefined) {
    this.contextMenuSpace = dashboard;
    this.menu.show(event);
  }

  get menuItems(): MenuItem[] {
    const result: MenuItem[] = [{
      label: 'Open in New Tab',
      icon: undefined,
      command: () => {
        if (this.contextMenuSpace) {
          const newUrl = `/dashboards?pane=${this.dashboardType}&id=${this.contextMenuSpace._id}`;
          window.open(newUrl, '_blank');
        }
      },
      disabled: !this.contextMenuSpace
    }, {
      label: 'Create',
      icon: undefined,
      command: () => {
        const parentNode = this.contextMenuSpace ? this.contextMenuSpace : this.rootNode;
        if (parentNode) {
          this.openNewDashboardDialog(parentNode); 
        }
      },
      disabled: !this.canCreateDashboard
    }, {
      label: 'Rename',
      icon: undefined,
      command: () => {
        if (this.contextMenuSpace) {
          this.openRenameDashboardDialog(this.contextMenuSpace);
        }
      },
      disabled: !(this.contextMenuSpace && this.canEditDashboard(this.contextMenuSpace._id ? this.contextMenuSpace._id : ""))
    }, {
      label: 'Duplicate',
      icon: undefined,
      command: () => {
        this.duplicateDashboards(false);
      },
      disabled: !(this.canCreateDashboard && (this.contextMenuSpace?._id && this.contextMenuSpace.path || this.isSelectedCurrent))
    }, {
      label: 'Duplicate Group',
      icon: undefined,
      command: () => {
        this.duplicateDashboards(true);
      },
      disabled: !(this.canCreateDashboard && (this.contextMenuSpace?._id && this.contextMenuSpace.path || this.isSelectedCurrent)),
      visible: !!this.contextOperationTarget?.length && !!this.contextOperationTarget.find(space => SpaceHelper.getChildren(this.dashboards, space)?.length)
    }, {
      label: 'Copy',
      icon: undefined,
      command: () => {
        const target = this.contextOperationTarget;
        if (target && target.length) {
          this.$store.commit("dashboard/copyDashboards", { dashboards: target, withChildren: false });
        } else {
          this.$store.commit("dashboard/copyDashboards", { dashboards: null, withChildren: false });
        }
      },
      disabled: !(this.canCreateDashboard && (this.contextMenuSpace?._id && this.contextMenuSpace.path || this.isSelectedCurrent))
    }, {
      label: 'Copy Group',
      icon: undefined,
      command: () => {
        const target = this.contextOperationTarget;
        if (target && target.length) {
          this.$store.commit("dashboard/copyDashboards", { dashboards: target, withChildren: true });
        } else {
          this.$store.commit("dashboard/copyDashboards", { dashboards: null, withChildren: true });
        }
      },
      disabled: !(this.canCreateDashboard && (this.contextMenuSpace?._id && this.contextMenuSpace.path || this.isSelectedCurrent)),
      visible: !!this.contextOperationTarget?.length && !!this.contextOperationTarget.find(space => SpaceHelper.getChildren(this.dashboards, space)?.length)
    }, {
      label: 'Paste',
      icon: undefined,
      command: () => {
        const toDashboard = this.contextMenuSpace ? this.contextMenuSpace : this.rootNode;
        if (toDashboard?._id) {
          this.pasteDashboards(toDashboard._id);
        }
      },
      disabled: !(this.canCreateDashboard && this.dashboardState.copiedDashboards?.length)
    }, {
      label: 'Delete',
      icon: undefined,
      command: () => {
        this.deleteDashboards(false);
      },
      disabled: !(this.canCreateDashboard && (this.contextMenuSpace?._id && this.contextMenuSpace.path || this.isSelectedCurrent))
    }, {
      label: 'Delete Group',
      icon: undefined,
      command: () => {
        this.deleteDashboards(true);
      },
      disabled: !(this.canCreateDashboard && (this.contextMenuSpace?._id && this.contextMenuSpace.path || this.isSelectedCurrent)),
      visible: !!this.contextOperationTarget?.length && !!this.contextOperationTarget.find(space => SpaceHelper.getChildren(this.dashboards, space)?.length)
    }, {
      label: 'Deselect',
      icon: undefined,
      command: () => {
        this.$store.commit("dashboard/unslectDashboardsAll");
      },
      visible: this.isSelectedAny
    }];
    // https://github.com/primefaces/primevue/issues/2268
    return result.map((item) => reactive(item));
  }

  async pasteDashboards(toId: string): Promise<void> {
    const target = this.dashboardState.copiedDashboards;
    if (target && target.length) {
      const input: CopySpaceModel[] = [];
      target.forEach(space => {
        if (space?._id) {
          input.push({ Id: space._id, ToId: toId});
        }
      });
      const payload: { input: CopySpaceModel[], organisationId: number | undefined, copyChildSpaces: boolean } = {
        input: input,
        organisationId: this.dashboardState.dashboardType === DashboardType.Organisation && this.organisationStore.currentOrganisation ? this.organisationStore.currentOrganisation.Id : undefined,
        copyChildSpaces: this.dashboardState.copiedWithChildren
      };
      await this.$store.dispatch("dashboard/copyDashboards", payload);
      this.$store.commit("dashboard/openDashboard", toId);
      ToastService.showToast("success", "Dashboards", "Dashboards copied successfully", 5000);
    }
  }

  duplicateDashboards(copyChildSpaces: boolean): void {
    const target = this.contextOperationTarget;
    if (target && target.length) {
      const message = `Are you sure you want to duplicate ${target.length} ${target.length > 1 ? 'dashboards' : 'dashboard'}${copyChildSpaces ? ' with children' : ''}?`;
      ConfirmationService.showConfirmation({
        message: message,
        header: 'Duplicate Dashboards',
        icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        rejectClass: 'p-button-secondary p-button-text',
        accept: async () => {
          // callback to execute when user confirms the action
          const input: CopySpaceModel[] = [];
          target.forEach(space => {
            if (space?._id) {
              const parentId = SpaceHelper.getParentId(space);
              if (parentId) {
                input.push({ Id: space._id, ToId: parentId});
              }  
            }
          });
          const payload: { input: CopySpaceModel[], organisationId: number | undefined, copyChildSpaces: boolean } = {
            input: input,
            organisationId: this.dashboardState.dashboardType === DashboardType.Organisation && this.organisationStore.currentOrganisation ? this.organisationStore.currentOrganisation.Id : undefined,
            copyChildSpaces: copyChildSpaces
          };
          await this.$store.dispatch("dashboard/copyDashboards", payload);
          ToastService.showToast("success", "Dashboards", "Dashboards copied successfully", 5000);
        },
        reject: () => {
          // callback to execute when user rejects the action
        }
      });
    }
  }

  deleteDashboards(deleteChildSpaces: boolean): void {
    const target = this.contextOperationTarget;
    if (target && target.length) {
      const message = `Are you sure you want to delete ${target.length} ${target.length > 1 ? 'dashboards' : 'dashboard'}${deleteChildSpaces ? ' group' : ''}?`;
      ConfirmationService.showConfirmation({
        message: message,
        header: 'Delete Dashboards',
        icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        rejectClass: 'p-button-secondary p-button-text',
        accept: async () => {
          // callback to execute when user confirms the action
          const ids: string[] = [];
          target.forEach(space => {
            if (space?._id) {
              ids.push(space._id); 
            }
          });
          const payload: { ids: string[], organisationId: number | undefined, deleteChildSpaces: boolean } = {
            ids: ids,
            organisationId: this.dashboardState.dashboardType === DashboardType.Organisation && this.organisationStore.currentOrganisation ? this.organisationStore.currentOrganisation.Id : undefined,
            deleteChildSpaces: deleteChildSpaces
          };
          await this.$store.dispatch("dashboard/deleteDashboards", payload);
          ToastService.showToast("success", "Dashboards", "Dashboards successfully removed", 5000);
        },
        reject: () => {
          // callback to execute when user rejects the action
        }
      });
    }
  }

  get contextOperationTarget(): Space[] | null {
    return this.isSelectedCurrent ? this.selectedCurrent : this.contextMenuSpace ? [this.contextMenuSpace] : null;
  }

  get selectedCurrent(): Space[] {
    const map = this.$store.getters["dashboard/getSelectedDashboards"] as Record<string, boolean>;
    const spaces = this.$store.getters["dashboard/getDashboards"] as Space[] | null;
    const result: Space[] = [];
    if (spaces && spaces.length && Object.keys(map).length) {
      for (const key in map) {
        if (map[key]) {
          const space = spaces.find(x => x._id === key);
          if (space) {
            result.push(space);
          }
        }
      }
    }
    return result;
  }

  get isSelectedCurrent(): boolean {
    const result = this.$store.getters["dashboard/getSelectedDashboardsCountCurrent"] > 0;
    return result;
  }

  get isSelectedAny(): boolean {
    const result = this.$store.getters["dashboard/getSelectedDashboardsCountTotal"] > 0;
    return result;
  }

  get canCreateDashboard(): boolean {
    const result = this.$store.getters["dashboard/canCreateDashboard"];
    return result;
  }

  get canDeleteDashboard(): boolean {
    const result = this.$store.getters["dashboard/canDeleteDashboard"];
    return result;
  }
  
  canEditDashboard(id: string): boolean {
    const canEditDashboard = this.$store.getters["dashboard/canEditDashboard"] as (id: string) => boolean;
    const result = canEditDashboard(id);
    return result;
  }
  // #endregion context menu
}

export default DashboardTreeRootView;
</script>